import React from 'react';
import styles from './AlertMessage.module.css';
import IMAGES from '../../assets/images/images';

const AlertMessage = ({ message, onClose }) => {
  return (
    <div className={styles.alertmessage}> 
      <div className={styles.alertcontent}>
      <div className={styles.closes}> <img className={styles.close} onClick={onClose} src={IMAGES.close}/></div>
        <div className={styles.errormsghead}>Account Disabled</div>
        <div className={styles.errormsg}>{message}</div>
        <div className={styles.buttonok}>
        <button className={styles.buttonscan} onClick={onClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default AlertMessage;