import React from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import IMAGES from '../../assets/images/images';

const customStyles = {
  control: (base) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#f9f9f9',
    '&:hover': {
      border: 'none',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: 'red',
    '&:hover': {
      color: 'darkred',
    },
    transition: 'color 0.3s',
  }),
  menuList: (base) => ({
    ...base,
    width: '250px',
    backgroundColor: '#ffffff',
    // marginLeft:'15px',
    borderRadius: '6px',
    border :'1px solid #999999',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  }),
  option: (base, state) => ({
    ...base,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    color: '#000',
    borderBottom: state.data.isLast ? 'none' : '1px solid #EBEBEB',
    '&:hover': {
      backgroundColor: '#f9f9f9',
      color: '#000',
    },
  }),
};

const Dropdown = ({ options, onChange, value }) => {
  const handleChange = (selectedOption) => {
    onChange(selectedOption);
  };

  const formattedOptions = options.map((option, index) => ({
    ...option,
    isLast: index === options.length - 1,
  }));

  return (
    <Select
      options={formattedOptions}
      onChange={handleChange}
      value={value}
      styles={customStyles}
      defaultValue={options[0]}
      isSearchable={false}
      placeholder={null}
      components={{ DropdownIndicator }}
      getOptionLabel={(option) => {
        const isSelected = value && value.label === option.label;
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: isSelected ? '100%' : '250px',
          }}>
            <span>{option.label}</span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#E60000',
                color: 'white',
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                fontSize: '12px',
                marginLeft: isSelected ? '5px' : '0',
              }}
            >
              {option.count}
            </span>
          </div>
        );
      }}
    />
  );
};

const DropdownIndicator = (props) => {
  const { selectProps } = props;
  const { menuIsOpen } = selectProps;

  return (
    <components.DropdownIndicator {...props}>
      <span style={{ color: 'red', fontSize: '18px' }}>
       <span><img style={menuIsOpen?{ color: 'red', height: '20px',width: '20px',marginBottom:'2px' ,transform:'rotate(180deg)'}:{ color: 'red',marginBottom:'2px', height: '20px',width: '20px' }}  src={IMAGES.arrowup}/></span>
      </span>
    </components.DropdownIndicator>
  );
};

export default Dropdown;