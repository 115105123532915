import React from 'react';
import styles from './footer.module.css';


export function Footer() {

    const leftElements = [
        { label: "Privacy Policy", 
          url: "https://www.vodafone.com/about-vodafone/where-we-operate/voice-and-roaming-services/privacy-policy?elqTrackId=0c95dbd4972a4a95abd8362975a920c3&elqaid=3714&elqat=2" 
        },
    ];

    const rightElement = "© 2025 Vodafone Group";

    return (
        <div className={styles.footer}>
            <div className={styles.leftside}>
                {leftElements.map((element, index) => (
                    <a key={index} href={element.url} target="_blank" rel="noopener noreferrer" className={styles.element}>
                        {element.label}
                    </a>
                ))}
            </div>
            <div className={styles.rightside}>
                {rightElement}
            </div>
        </div>
    );
}
