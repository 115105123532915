import {
    BASE_URL, 
    FINCA_CODE,
    PATCH,
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import { getAccessToken } from "../../authActions/authActions";
  
  export const addFincaId = async (payload,requestId) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${FINCA_CODE}${requestId}`;
    return fetch(resource, {
      method: PATCH,
      headers: {
        "Content-Type": "application/json",
        "userauth": `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
      .then(statusParsing)
      .then(jsonParsing);
  };
  
  