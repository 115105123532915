import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const dropdownStyle = {
    control: (provided, state) => {
        const { selectProps: { hasError }, isDisabled } = state;
        return {
            ...provided,
            backgroundColor: isDisabled ? "#999" : "#efefef",
            borderRadius: 5,
            height: 48,
            borderWidth: 1,
            borderColor: hasError ? "#CD3500" : "#C5C1C8"
        };
    },
    singleValue: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "#100045",
        fontFamily: "VodafoneRg-Regular",
        fontSize: 13
    }),
    placeholder: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "#837B8B",
        fontFamily: "VodafoneRg-Regular",
        fontSize: 13
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        width: 0
    }),
    container: (provided, state) => {
        const { selectProps: { customStyles } } = state;
        return {
            ...provided,
            ...customStyles
        };
    },
    menuList: (provided) => ({
        ...provided,
        maxHeight: 200,
        overflowY: 'auto',
        position: 'relative',
        WebkitOverflowScrolling: 'touch',
        paddingBottom: 4,
        paddingTop: 4,
        boxSizing: 'border-box'
    }),
    valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'nowrap',
        overflowX: 'auto',
        maxWidth: '100%'
    }),
    MultiValueRemove:(provided) =>({
        ...provided,
        display:"none",
    }),
};

const defaultCustomStyles = {
    width: "auto"
};

const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
                style={{ marginRight: 8 }}
            />
            <label>{props.label}</label>
        </components.Option>
    );
};
const CustomMultiValueRemove = () => null;
const DropDown = ({ options, placeholder, onChange, value, hasError, customStyles = defaultCustomStyles, menuIsOpen }) => {
    const handleChange = (selected) => {
        if (selected.some(option => option.value === 'all')) {
            if (selected.length === options.length + 1) {
                onChange([]);
            } else {
                if (selected.length === options.length) {
                    onChange([]);
                } else {
                    onChange(options);
                }
            }
        } else {
            if (selected.length === options.length) {
                onChange([]);
            } else {
                onChange(selected);
            }
        }
    };

    const isAllSelected = value.length === options.length;

    return (
        <Select
            placeholder={placeholder}
            options={[{ value: 'all', label: 'All' }, ...options]}
            isMulti={true}
            onChange={handleChange}
            styles={dropdownStyle}
            hasError={hasError}
            value={isAllSelected ? [{ value: 'all', label: 'All' }, ...options] : value}
            customStyles={customStyles}
            menuIsOpen={menuIsOpen}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option: CustomOption, MultiValueRemove: CustomMultiValueRemove }}
        />
    );
};

DropDown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
    hasError: PropTypes.bool,
    customStyles: PropTypes.object,
    menuIsOpen: PropTypes.bool
};

DropDown.defaultProps = {
    placeholder: "Select",
    value: [],
    hasError: false,
    customStyles: defaultCustomStyles,
    menuIsOpen: false
};

export default DropDown;