import React from 'react';
import styles from './dynamicTable.module.css';
import { USER_TYPE } from '../../utils/constants/userConstants';
import IMAGES from '../../assets/images/images';

const DynamicTable = ({ headers,  data,columnWidths, ...props }) => {
  return (
    <div className={styles.border}>
    <div className={styles.table} {...props}>
    <div className={styles.thead}>
        <div className={styles.tr}>
          {headers?.map((header, index) => (
            <div
              key={index}
              className={`${styles.th} ${props.invoice==="invoice"?styles.invoiceheader:index===headers.length-1?"":styles.tableheader} ${
                (index === 1 && props.userType!==USER_TYPE.CUSTOMER && props.invoice!=="invoice") ? styles.thFlex2 : ''
              }`}
              style={{ flex: columnWidths ? columnWidths[index] : 1 }}
            >
              {header}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.tbody}>
      {data?.map((row, rowIndex) => (
        <div key={rowIndex} className={styles.tr}>
          {headers?.map((header, colIndex) => (
            <div
              key={colIndex}
              className={`${styles.td} ${props.invoice==="invoice"?styles.invoice:colIndex===headers.length-1?"":styles.tableheader} ${
                (colIndex === 1 && props.userType!==USER_TYPE.CUSTOMER && props.invoice!=="invoice") ? styles.tdFlex2 : ''
              }`}
              style={{ flex: columnWidths ? columnWidths[colIndex] : 1 }}
            >
              {row[header.key || header]}
              {/* {row[header]} */}
            </div>
          ))}
        </div>
      ))}
    </div>
    </div>
    </div>
  );
};

export default DynamicTable;