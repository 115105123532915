import { getEventNotificationList, ReadNotification } from "../../redux/actions/notification/getEventNotificationList.action";
import Notification from "./Notification";
import { connect } from "react-redux";

const mapStateToProps = (state) => {

  return {
    eventnotification:state.notifications.eventNotification
 }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEventNotificationList: (next=null)=>{
      return dispatch(getEventNotificationList(next))
  },
    ReadNotification: (payload,id) => {
      return dispatch(ReadNotification(payload,id))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
