// 


import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useId } from 'react';
import styles from './status.module.css';
import SecondaryTooltip from '../secondaryToolTip/secondaryToolTip';
import { USER_TYPE } from '../../utils/constants/userConstants';

const Status = ({ statuses, onStatusChange, infoImage, currentUserType, onResetfilter }) => {
  const [checkedStatuses, setCheckedStatuses] = useState(
    statuses.reduce((acc, status) => ({ ...acc, [status.id]: true }), {})
  );

  useEffect(() => {
    onStatusChange(checkedStatuses);
  }, [checkedStatuses, onStatusChange]);

  const handleCheckboxChange = (statusId) => {
    setCheckedStatuses((prev) => {
      const newCheckedStatuses = { ...prev, [statusId]: !prev[statusId] };

      if (statusId === 'all') {
        const allChecked = newCheckedStatuses[statusId];
        statuses.forEach(status => {
          newCheckedStatuses[status.id] = allChecked;
        });
      } else {
        if (!newCheckedStatuses[statusId]) {
          newCheckedStatuses.all = false;
        } else {
          const allSpecificStatusesChecked = statuses.every(status => status.id === 'all' || newCheckedStatuses[status.id]);
          newCheckedStatuses.all = allSpecificStatusesChecked;
        }
      }
      onResetfilter();
      return newCheckedStatuses;
    });
  };

  const CheckBox = ({ name, value, label, checked, onChange,color,infoMessage }) => {
    console.log()
    const id = useId();
    return (
      <div className={styles.radioField}>
        <input
          id={id}
          className={`${styles.radioInput}`}
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          style={{ accentColor: color }}
        />
        <label htmlFor={id} className={styles.radioLabel}>
          {label}
        </label>
        {infoImage && infoMessage && currentUserType===USER_TYPE.INTERNAL && (
        <SecondaryTooltip text={infoMessage} positions={['bottom', 'right']}>
          <img src={infoImage} alt="Info" className={styles.infoImage} />
        </SecondaryTooltip>
        )}
      </div>
    );
  };

  return (
    <div className={styles.statusContainer}>
      <div className={styles.labeltext}>Status:</div>
      {statuses.map((status) => (
        <CheckBox
          key={status.id}
          name="statuses"
          className={styles.radioInput}
          value={status.id}
          label={`${status.label} (${status.count})`}
          checked={checkedStatuses[status.id]}
          onChange={() => handleCheckboxChange(status.id)}
          color={status.color}
          infoMessage={status.infoMessage}
        />
      ))}
    </div>
  );
};

Status.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
  onStatusChange: PropTypes.func.isRequired,
  infoImage: PropTypes.string,
};

export default Status;