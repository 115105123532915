import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./customCalendar.module.css";
import IMAGES from "../../../assets/images/images";
import { OPTIONS } from "../../../utils/constants/fincaConstant";

export const CustomCalendar = ({ actions, initialValues }) => {
  const { onApply, setSelectValue, setShowCalendar, selectRef, setMenuIsOpen } = actions;
  const [selectedYear, setSelectedYear] = useState(initialValues.selectedYear || new Date().getFullYear());
  const initialdata = initialValues.selectedMonths===''?initialValues.selectedMonths:initialValues?.selectedMonths?.sort((a, b) => new Date(a) - new Date(b))
  const [selectedMonths, setSelectedMonths] = useState(initialdata || []);
  const [selectionType, setSelectionType] = useState(initialValues.selectionType || OPTIONS.SINGLE);


  const calendarRef = useRef(null);

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const handleMonthClick = (index) => {
    const clickedMonth = index + 1;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    if (selectedYear > currentYear || (selectedYear === currentYear && clickedMonth > currentMonth)) {
      return;
    }

    const selectedDate = `${selectedYear}-${String(clickedMonth).padStart(2, "0")}`;

    if (selectionType === OPTIONS.SINGLE) {
      setSelectedMonths([selectedDate]);
    } else {
      if (selectedMonths.includes(selectedDate)) {
        setSelectedMonths([selectedDate]);
      } else {
        if (selectedMonths.length > 1) {
          setSelectedMonths([selectedDate]);
        } else {
          const newSelection = [...selectedMonths, selectedDate].sort();
          const startMonth = newSelection[0];
          const endMonth = newSelection[newSelection.length - 1];
          const monthRange = generateMonthRange(startMonth, endMonth);

          setSelectedMonths(monthRange);
        }
      }
    }
  };

  const handleYearChange = (direction) => {
    const newYear = selectedYear + direction;
    const currentYear = new Date().getFullYear();

    if (newYear > currentYear) {
      return; // Prevent changing to future years
    }

    setSelectedYear(newYear);
  };

  const handleOutsideClick = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      onApply(null);
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const generateMonthRange = (start, end) => {
    const range = [];
    const [startYear, startMonth] = start.split("-");
    const [endYear, endMonth] = end.split("-");

    for (let year = parseInt(startYear); year <= parseInt(endYear); year++) {
      const startM = year === parseInt(startYear) ? parseInt(startMonth) : 1;
      const endM = year === parseInt(endYear) ? parseInt(endMonth) : 12;

      for (let month = startM; month <= endM; month++) {
        range.push(`${year}-${String(month).padStart(2, "0")}`);
      }
    }

    return range;
  };

  const handleApply = () => {
    if (selectedMonths.length > 0) {
      const formattedDates = selectedMonths;

      const startDate = new Date(formattedDates[0]);
      const endDate = new Date(formattedDates[formattedDates.length - 1]);

      const startMonthName = startDate.toLocaleString("default", { month: "long" });
      const endMonthName = endDate.toLocaleString("default", { month: "long" });

      const startYear = startDate.getFullYear().toString();
      const endYear = endDate.getFullYear().toString();

      const customLabel = selectionType === OPTIONS.MULTIPLE
        ? `Custom: ${startMonthName} ${startYear} - ${endMonthName} ${endYear}`
        : `Custom: ${startMonthName} ${startYear}`;

      setSelectValue({ label: customLabel, value: formattedDates });
      // onApply(formattedDates);
      onApply(formattedDates, customLabel);
      if (calendarRef.current) {
        calendarRef.current.style.display = 'none';
      }
    }
  };

  const handleRadioChange = (event) => {
    setSelectionType(event.target.value);
    setSelectedMonths([]);
  };

  const isApplyButtonDisabled = () => {
    if (selectionType === OPTIONS.SINGLE) {
      return selectedMonths.length !== 1;
    } else if (selectionType === OPTIONS.MULTIPLE) {
      return selectedMonths.length < 2;
    }
    return true;
  };

  return (
    <div ref={calendarRef} className={styles.calendarContainer}>
      {/* <div className={styles.customtext}>
        <img className={styles.chevronredleft} src={IMAGES.arrows} onClick={() => { setMenuIsOpen(true); setSelectValue({ label: '', value: '' }); setShowCalendar(false); selectRef.current.focus(); selectRef.current.openMenu(); }} alt="Arrow" />
        Custom
      </div> */}
      <div className={styles.calendarHeader}>
        <div className={styles.selectmonth}>Select Month</div>
        <div className={styles.radioContainer}>
          <label className={styles.radioDiv}>
            <input
              type="radio"
              value={OPTIONS.SINGLE}
              checked={selectionType === OPTIONS.SINGLE}
              onChange={handleRadioChange}
            />
            <span className={styles.radiotext}>Single</span>
          </label>
          <label className={styles.radioDiv}>
            <input
              type="radio"
              value={OPTIONS.MULTIPLE}
              checked={selectionType === OPTIONS.MULTIPLE}
              onChange={handleRadioChange}
            />
            <span className={styles.radiotext}>Multiple</span>
          </label>
        </div>
      </div>
      <div className={styles.combination}>
        <div className={styles.monthlabel}>
          <img className={styles.backarrow} onClick={() => handleYearChange(-1)} src={IMAGES.back} alt="Back" />
          <div className={styles.calendarYear}>{selectedYear}</div>
          <img className={`${selectedYear < new Date().getFullYear() ? styles.backarrow : styles.disablearrow} ${styles.rightarrow}`} onClick={() => handleYearChange(1)} src={IMAGES.back} alt="Forward" />
        </div>
        <div className={styles.calendarGrid}>
          {months.map((month, index) => {
            const monthDate = `${selectedYear}-${String(index + 1).padStart(2, "0")}`;
            const isFutureMonth = selectedYear === new Date().getFullYear() && index + 1 > new Date().getMonth() + 1;
            return (
              <div
                key={month}
                className={`${styles.calendarMonth} ${
                  selectedMonths.includes(monthDate) ? styles.selected : ""
                } ${
                  selectedMonths.length > 1 && selectedMonths.includes(monthDate) && monthDate !== selectedMonths[0] && monthDate !== selectedMonths[selectedMonths.length - 1] ? styles.rangeSelected : ""
                } ${
                  selectedMonths.length > 1 && (monthDate === selectedMonths[0] || monthDate === selectedMonths[selectedMonths.length - 1]) ? styles.selected : ""
                } ${isFutureMonth ? styles.disabled : styles.activebtn} ${(selectedMonths.length === 2 && monthDate === selectedMonths[selectedMonths.length - 1])? styles.lastSelected : ""}`}
                onClick={() => !isFutureMonth && handleMonthClick(index)}
              >
                <span>{month}</span>
              </div>
            );
          })}
        </div>
      </div>
      {/* <button className={`${styles.applyButton} ${selectedMonths.length === 0 && styles.disableapplybtn}`} onClick={handleApply} disabled={true}>Apply</button> */}
      <button className={`${styles.applyButton} ${isApplyButtonDisabled() && styles.disableapplybtn}`} onClick={handleApply} disabled={isApplyButtonDisabled()}>Apply</button>
    </div>
  );
};

CustomCalendar.propTypes = {
  actions: PropTypes.shape({
    onApply: PropTypes.func.isRequired,
    setSelectValue: PropTypes.func.isRequired,
    selectRef: PropTypes.object.isRequired
  }).isRequired,
  initialValues: PropTypes.shape({
    selectedYear: PropTypes.number,
    selectedMonths: PropTypes.arrayOf(PropTypes.string),
    selectionType: PropTypes.oneOf([OPTIONS.SINGLE, OPTIONS.MULTIPLE])
  })
};

export default CustomCalendar;