import { SUBMIT_FINCA_ID} from "../actionTypes";
import { addFincaId } from "../../../api/finca/submitfincaCode.service";

const request = () => ({ type: SUBMIT_FINCA_ID.REQUEST });
const failure = (error) => ({ type: SUBMIT_FINCA_ID.FAILURE, error });
const success = () => ({ type: SUBMIT_FINCA_ID.SUCCESS });

export const SubmitFincaId = (payload,requestId) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addFincaId(payload,requestId)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      });
  };
};