import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './modules/login/login.container';
import Dashboard from './modules/dashboard/dashboard.container';
import CAN from './casl/can';
import { defineRulesForUser } from "./casl/can";
import { Action, Resource } from './casl/constants';
import { connect } from "react-redux";
import { useEffect, useState, useCallback } from 'react';
import { app } from './configuration/gcp-identity-platform-config';
import { getAuth, onAuthStateChanged, getRedirectResult } from "firebase/auth";
import { getMyRights, updateMyRights, updateIsLoggedIn } from './redux/actions/userManagement/myRights.action';
import AuthGuard from "./hoc/guards/AuthGuard";
import ServicesViewer from './modules/newService/viewer/ServicesViewer';
import Navbar from "./modules/header/NavBar";
import Service from './modules/newService/service/service.container';
import GenericInformation from './modules/genericInformation/GenericInformation.container';
import UserManagement from './modules/userManagement/UserManagement';
import ContactUs from './modules/contactUs/contactus.container';
import CustomerDetail from './modules/userManagement/customer/detail/CustomerDetail.container';
import MFA from "./modules/mfa/multifactorEnabler.container";
import { Footer } from './modules/footer/Footer';
import RouteGuard from './hoc/guards/RouteGuard';
import InvalidUser from './modules/invalidUser/InvalidUser';
import InactiveUser from './modules/inactiveUser/InactiveUser';
import { USER_TYPE } from './utils/constants/userConstants';
import ValidateEmail from './modules/emailValidation/validateEmail.container';
//import useAutoLogout from './modules/inactiveTimeout/Timeout';
//import WarningPopup from './modules/inactiveTimeout/WarningPopup';
//import SessionExpire from './modules/inactiveTimeout/sessionExpire';
import { Alert } from './components/alert/alerts';
import { closeAlertPopup } from './redux/actions/Alert/alert.action';
import IncidentManagement from './modules/incidentManagement/incidentManagement';
import IncidentDetail from './modules/incidentManagement/myIncidents/incidentDetail/incidentDetail.container';
import ServiceRequestDetail from './modules/incidentManagement/myServiceRequest/serviceRequestDetail/serviceRequestDetail.container';
import IdleTimer from './IdleTimer/IdleTimer';
import OnboardingDetail from './modules/userManagement/customer/myRequest/onboardingApproval/detail/onboardingDetail.container';
import OnboardingRequestDetail from './modules/userManagement/customer/myRequest/onboardingRequest/detail/onboardingRequestDetail.container';
import AccountAccessRequestDetail from './modules/userManagement/customer/myRequest/accountAccessRequest/detail/accountAccessRequestDetail.container';
import AccountAccessApprovalDetail from './modules/userManagement/customer/myRequest/accountAccessApproval/detail/accountAccessApprovalDetail.container';
import AccessApprovalDetail from './modules/userManagement/customer/accessApprovalDetails/accessApprovalDetail.container';
import FincaInvoices from './modules/finca/list/fincaInvoices.container';
import AlertMessage from './components/loginAlertMessage/AlertMessage';
import {  setAlertMessage,clearAlertMessage } from './redux/actions/AlertErrorMessage/alertErrorMessage.action';
import BroadCastNotification from './modules/notification/broadCastNotification/broadCastNotification.container';
function App(props) {
  const { myRights, myRightsLoading, userStatus, message, isAlertPopupOpen, isUserLoggedIn } = props;

  //const { showWarning,startSession,endSession, continueSession,hideWarningPopup,hideExpirepopup ,expire} = useAutoLogout();

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {

      console.log("onAuthStateChanged user", user);

      if (user) {
        user.reload() // to fix reloadUserInfo null issue  - CUP-1209
        props.getRights();
      } else {
        props.updateRights(null);
        //endSession();
        props.updateIsLoggedIn(false);
      }
    });

    // Handle redirect and get user details
    const getUserDetails = async () => {
      try {
        const userCred = await getRedirectResult(auth);
        console.log("userCred", userCred);
      }
      catch (error) {
        console.log("error", error);
      }
    };

    getUserDetails();
  }, []);

  // Define the constant using the some method
  const canAccessUserManagement = [
    CAN(Action.LIST, Resource.CUSTOMERS),
    CAN(Action.LIST, Resource.RIGHTS),
    CAN(Action.LIST, Resource.CUSTOMER_ONBOARDING_REQUEST)
  ].some(condition => condition);

  const handleCloseAlert = () => {
    props.clearAlertMessage();
  };
  /*useEffect(()=>{
    defineRulesForUser(myRights?.permissions);
  },[myRights]);*/
  defineRulesForUser(myRights?.permissions);
  const emailVerified = myRights ? getAuth(app).currentUser?.emailVerified : false;
  const isValidMFAUser = getAuth(app).currentUser?.providerData[0]?.providerId === "password" ? true : false
  const hasMFAEnabled = getAuth(app).currentUser?.reloadUserInfo?.mfaInfo ? true : false;
  const isActive = !getAuth(app).currentUser?.reloadUserInfo?.disabled ? true : false;
  return (
    <BrowserRouter>
     {props.alertMessage && <AlertMessage message={props.alertMessage} onClose={handleCloseAlert} />}
      {/*(((myRights && myRights?.status == "active" && emailVerified && isValidMFAUser && hasMFAEnabled) || (myRights && myRights?.status == "active" && !hasMFAEnabled && !isValidMFAUser && emailVerified)) && isActive)?
        showWarning && (
        <WarningPopup onContinue={continueSession}  onhide={hideWarningPopup}  />
      ) :<></>*/}
      {/*expire && ( <SessionExpire hideExpirepopup={hideExpirepopup} /> )*/}
      <IdleTimer isUserLoggedIn={isUserLoggedIn} />
      <AuthGuard userDetails={myRights} isAppLoading={myRightsLoading} userStatus={userStatus} updateIsLoggedIn={props.updateIsLoggedIn}>
        <div style={{ backgroundColor: "#F4F4F4" }}>
          {(((myRights && myRights?.status == "active" && emailVerified && isValidMFAUser && hasMFAEnabled) || (myRights && myRights?.status == "active" && !hasMFAEnabled && !isValidMFAUser && emailVerified)) && isActive) ? <><Navbar /><BroadCastNotification/> </>: <></>}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<RouteGuard canAccess={CAN(Action.LIST, Resource.IPX_POP)}><GenericInformation /></RouteGuard>} />
            <Route path="/insights/:data?" element={<RouteGuard canAccess={CAN(Action.GET, Resource.DIAMETER)}><Dashboard /></RouteGuard>} />
            <Route path="/connectivity" element={<RouteGuard canAccess={CAN(Action.LIST, Resource.LIVE_SERVICES) || CAN(Action.GET, Resource.DEMANDS)}><ServicesViewer /></RouteGuard>} />
            <Route path="/demands/:requestId" element={<RouteGuard canAccess={CAN(Action.GET, Resource.DEMANDS)}><Service /></RouteGuard>} />
            <Route path="/contact-us" element={<RouteGuard canAccess={CAN(Action.CREATE, Resource.QUERY)}><ContactUs /></RouteGuard>} />
            <Route path="/user-management" element={myRights?.userType == USER_TYPE.INTERNAL ? <RouteGuard canAccess={canAccessUserManagement}><UserManagement /></RouteGuard> : <RouteGuard canAccess={CAN(Action.GET, Resource.CUSTOMERS)}><CustomerDetail customerId={myRights?.customerUserMetaInfo?.uniqueCustomerRefId} /></RouteGuard>} />
            <Route path='/enable-mfa' element={<MFA />} />
            <Route path="/verifyemail" element={<ValidateEmail />} />
            <Route path="/invalid-user" element={<InvalidUser />} />
            <Route path="/inactive-user" element={<InactiveUser />} />
            <Route path="/incident-management" element={<RouteGuard canAccess={CAN(Action.LIST, Resource.INCIDENTS) || CAN(Action.LIST, Resource.SERVICE_REQUESTS)}><IncidentManagement /></RouteGuard>} />
            <Route path="/incidentDetail/:requestId" element={<RouteGuard canAccess={CAN(Action.GET, Resource.INCIDENTS)}><IncidentDetail /></RouteGuard>} />
            <Route path='/invoices' element={<RouteGuard canAccess={CAN(Action.LIST, Resource.FINCAINVOICE)}><FincaInvoices /></RouteGuard>} />
            <Route path="/serviceRequestDetail/:requestId" element={<RouteGuard canAccess={CAN(Action.GET, Resource.SERVICE_REQUESTS)}><ServiceRequestDetail /></RouteGuard>} />
            <Route path="/OnboardingDetail/:requestId" element={<RouteGuard canAccess={CAN(Action.GET, Resource.CUSTOMER_ONBOARDING_APPROVAL)}><OnboardingDetail /></RouteGuard>} />
            <Route path="/OnboardingRequestDetail/:requestId" element={<RouteGuard canAccess={CAN(Action.GET, Resource.CUSTOMER_ONBOARDING_REQUEST)}><OnboardingRequestDetail /></RouteGuard>} />
            {/* <Route path="/accountAccessRequest/:requestId" element={<AccountAccessRequestDetail />} />
            <Route path="/accountAccessApproval/:requestId" element={<AccountAccessApprovalDetail />} /> */}
            <Route path="/AccessApprovalDetail/:requestId"  element={<AccessApprovalDetail />} />
          </Routes>
          {(((myRights && myRights?.status == "active" && emailVerified && isValidMFAUser && hasMFAEnabled) || (myRights && myRights?.status == "active" && !hasMFAEnabled && !isValidMFAUser && emailVerified)) && isActive) ? <Footer /> : <></>}
          {
            isAlertPopupOpen && (<Alert
              image={props.message.image}
              message={props.message.message}
              messageTimeout={props.message?.messageTimeout ? props.message.messageTimeout : 8000}
              isFormOpen={props.message.isFormOpen ? props.message.isFormOpen : null}
              onClose={props.closeAlertPopup}
              isAlertPopupOpen={isAlertPopupOpen}
            />
            )}
        </div>
      </AuthGuard>
    </BrowserRouter>

  );
}

const mapStateToProps = (state) => {
  return {
    myRights: state.myRights.myRights,
    myRightsLoading: state.myRights.loading,
    userStatus: state.myRights.userStatus,
    message: state.alert.alertDetails,
    isAlertPopupOpen: state.alert.isAlertPopupOpen,
    isUserLoggedIn: state.myRights.isLoggedIn,
    alertMessage: state.alertErrorMessage.alertMessage
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRights: (userDetails) => {
      return dispatch(getMyRights(userDetails))
    },
    updateRights: (userDetails) => {
      return dispatch(updateMyRights(userDetails))
    },
    closeAlertPopup: () => {
      return dispatch(closeAlertPopup())
    },
    updateIsLoggedIn: (isLoggedIn) => {
      return dispatch(updateIsLoggedIn(isLoggedIn))
    },
    setAlertMessage: (message) => {
      return dispatch(setAlertMessage(message))
    },
    clearAlertMessage: () => {
      return dispatch(clearAlertMessage())
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
