import { FETCH_INVOICE_DETAILS, CLEAR_INVOICE_DETAIL } from "../actionTypes"
import { fetchInvoiceDetails } from "../../../api/finca/invoiceDetails.service"


const requestDetailRequest = ()=>({type: FETCH_INVOICE_DETAILS.REQUEST})
const requestDetailFailure = (error)=>({type: FETCH_INVOICE_DETAILS.FAILURE, error})
const requestDetailSuccess = (obj)=>({type: FETCH_INVOICE_DETAILS.SUCCESS, obj})
const clearInvoiceData = () => ({ type: CLEAR_INVOICE_DETAIL });


export const getInvoiceDetails = (invoicePeriod, fincaId) => {
  return (dispatch, getState) => {
    dispatch(requestDetailRequest())
    return fetchInvoiceDetails(invoicePeriod, fincaId)
      .then((response) => {
        // // console.log("response",response)
        // let obj = {
        //   data: response.data,
        //     isLoadMore: true
        // }
        // // console.log("obj",obj)
        dispatch(requestDetailSuccess(response))
        return Promise.resolve(true)
      })
      .catch((error) => {
        dispatch(requestDetailFailure(error?.message))
        return Promise.resolve(false)
      })
  }
}

export const clearInvoiceDetail = () => {
  return (dispatch) => {
    dispatch(clearInvoiceData());
  };
}