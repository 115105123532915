import React, { useState } from 'react';
import styles from './otp.module.css';
import IMAGES from '../../assets/images/images';

const OTPModal = ({ promptMessage, onSubmit, onCancel }) => {
  const [verificationCode, setVerificationCode] = useState('');

  const handleChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(verificationCode);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
      <div className={styles.closes}> <img className={styles.close} onClick={onCancel} src={IMAGES.close}/></div>
        <div className={styles.head}>Account Verification</div>
        <p className={styles.message}>{promptMessage}</p>
        <div className={styles.label}>Verification Code</div>
        <input
          type="text"
          value={verificationCode}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter Code"
          className={styles.input}
        />
        <div className={styles.buttons}>
        <button className={styles.buttonscan} onClick={onCancel}>Cancel</button>
        <button className={ !verificationCode ? styles.notvalid :styles.buttonssub} disabled={!verificationCode} onClick={handleSubmit}>Verify</button>
        </div>
      </div>
    </div>
  );
};

export default OTPModal;