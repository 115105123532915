import styles from "./list.module.css"
import {serviceStatusColor,SERVICE_STATUS_OPTIONS} from "../../../src/utils/constants/serviceConstants";
import { useNavigate } from "react-router-dom";
import SecondaryTooltip from "../secondaryToolTip/secondaryToolTip";

export const ServiceDetailContainer = ({children, statusName})=>{
   // var borderColor = Object.keys(serviceStatusColor).find(key => serviceStatusColor[key] === statusName.trim());
    
    return (
        <div className={styles.serviceDetailContainer}>{children }</div>
    );
}
export const ServiceHeader = ({children}) => {   
    return (
        <div className={styles.serviceHeader}>{children}</div>
    );

}

export const ServiceName = ({displayId}) => {  
    return (
        <div className={styles.serviceName}>{displayId}</div>                
    );
}

export const ServiceMiddleDiv = ({children})=>{
    return (
        <div className={styles.serviceMiddleDiv}>{children}</div>
    );
}

export const ServiceDescription = ({description}) => {  
    return (
        <div className={styles.descriptionContainer}>{description}</div>                
    );
}

export const DateOfSubmission = ({date}) => {  
    return (
        <div ><span className={styles.submittedOn}>Submitted On: </span> <span className={styles.createdDate}>{date}</span></div>                
    );
}

export const ServiceFooter = ({companyName, location, requestId, createdBy, createdByEmail, goToServiceDetails, displayId, isInternalUser, country,serviceName,date,createdByemail}) => {  
    return (
        <div className={styles.serviceFooter} >
            { isInternalUser?
                <div className={styles.companyDiv }><span>Organisation Name: </span><span>{companyName}</span></div>
                : <></>
            }          
            <div className={styles.requestId}><span class="serviceRequest" id={requestId}>Service: </span><span>{serviceName}</span></div>
            <div className={styles.location}><span>Region: </span><span> {location}</span></div>
            <div className={styles.country}><span>Country: </span><span> {country}</span></div>
            <div className={styles.requestor}><span>Requestor:  </span>
            <SecondaryTooltip copy={true}text={createdByemail} positions={['bottom', 'right']}>
            <span>{createdBy}</span>
            </SecondaryTooltip>
            </div>
            <div className={styles.requestor}><span>Submitted On: </span><span>{date}</span></div>
        </div>                
    );
}

export const ServiceListContainer = ({children})=>{
    return (
        <div className={styles.serviceListContainer}>{children}</div>
    );
}

export const StatusContainer = ({statusName}) => {
    //var backgroundColor = Object?.keys(serviceStatusColor).find(key => serviceStatusColor[key] === statusName?.trim());
    //style={{ backgroundColor: `${backgroundColor}`, border:`2px solid #89DBE9` }}
    const obj = SERVICE_STATUS_OPTIONS.find(({ value }) => value ===statusName);
    return (
        <div className={ `${styles.statusContainer} ${styles[statusName?.trim()]}`}>{obj?.label}</div>
    ); 
}


export const IconsContainer = ({children})=>{
    return (
        <div className={styles.iconsContainer}>{children}</div>
    );
}



