import { FETCH_FINCATADIG } from "../../actions/actionTypes";

const initialState = {
    data: [],
    isLoading: false,
    // next:null
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_FINCATADIG.REQUEST:
            return {...state, isLoading: true}
        case FETCH_FINCATADIG.FAILURE:
            return {...state, isLoading:false}
        case FETCH_FINCATADIG.SUCCESS:     
        return {
            ...state,
            data: action.obj.isLoadMore ? [...state.data,...action.obj.data] : action.obj.data,
            // next: action.obj.next,
            isLoading:false
          };      
        default:
            return state
    }   
}

export default reducer;