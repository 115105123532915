import React, { useEffect } from "react";
import styles from "./broadCastNotification.module.css";

const BroadCastNotification = (props) => {
    
  useEffect(() => {  
      props.getBroadCastNotificationList();    
  }, []);

  return (
    <>
    {props.broadCastnotification?.map((notification, index) => (
      <div className={styles.notification}>{notification.message}</div>
    ))}
    </>
  );
};

export default BroadCastNotification;