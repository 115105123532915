import {
    BASE_URL, 
    PATCH,  
   USER_ACTIVE_INACTIVE
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import { getAccessToken } from "../../authActions/authActions";
  
  export const submitActiveInactive = async (payload,id) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${USER_ACTIVE_INACTIVE}${id}`;
    return fetch(resource, {
      method: PATCH,
      headers: {
        "Content-Type": "application/json",
        "userauth": `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
      .then(statusParsing)
      .then(jsonParsing);
  };
