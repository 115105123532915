export const INVOICE_STATUS = {
  paid: "Paid",
  clientApproved: "ClientApproved",
  postedToEVO: "PostedToEVO"
}

export const INVOICE_CUSTOM_STATUS = {
  due: "Due"
}

export const getTooltipText = (documentType) => {
  switch (documentType) {
    case 'RIC':
      return 'Outgoing Invoice';
    case 'CIV':
      return 'Incoming Invoice';
    case 'OCNC':
      return 'Outgoing Credit Note To Customer';
    case 'ODNC':
      return 'Outgoing Debit Note To Customer';
    case 'ICNV':
      return 'Incoming Credit Note From Vendor';
    case 'IDNC':
      return 'Incoming Debit Note From Customer';
    default:
      return documentType;
  }
};

export const OPTIONS = { SINGLE: "Single", MULTIPLE: "Multiple"}; 

export const filterInvoices = (invoiceData, checkedStatuses) => {
  return invoiceData.filter(invoice => {
    if (checkedStatuses.clientApproved && invoice.status === INVOICE_STATUS.clientApproved) return true;
    if (checkedStatuses.postedToEvo && invoice.status === INVOICE_STATUS.postedToEVO) return true;
    if (checkedStatuses.paid && invoice.status === INVOICE_STATUS.paid) return true;
    return false;
  });
};


export const columnConfig = [
  { name: 'invoicedPartyName', flex: 2 },
  { name: 'invoicingPartyName', flex: 2 },
  { name: 'Invoice Number', flex: 2 },
  { name: 'Invoice Period', flex: 1 },
  { name: 'Amount', flex: 1 },
  { name: 'Invoice Type', flex: 1 },
  { name: 'documentDate', flex: 1.5 },
  { name: 'Invoice Due Date', flex: 2 },
];