import { FETCH_BROADCAST_NOTIFICATION_LIST } from "../actionTypes"
import { fetchBroadCastNotificationList } from "../../../api/notification/getBroadCastNotification.service"

const notificationRequest = ()=>({type: FETCH_BROADCAST_NOTIFICATION_LIST.REQUEST})
const notificationFailure = (error)=>({type: FETCH_BROADCAST_NOTIFICATION_LIST.FAILURE, error})
const notificationSuccess = (obj)=>({type: FETCH_BROADCAST_NOTIFICATION_LIST.SUCCESS, obj})

export const getBroadCastNotificationList = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(notificationRequest())
        return fetchBroadCastNotificationList(requestId)
        .then(response => {
            dispatch(notificationSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(notificationFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
