import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DropDown from '../dropDown/MultDropdownCheckbox';
import styles from './filter.module.css';
import IMAGES from '../../assets/images/images';

export const Filter = ({ isOpen, onRequestClose, options, onApply, label, initialSelectedOptions, onReset }) => {
    const [selectedFilterOptions, setSelectedFilterOptions] = useState(initialSelectedOptions || []);
    const filterRef = useRef(null);

    const uniqueOptions = Array.from(new Set(options.map(option => option.value)))
        .map(value => options.find(option => option.value === value));

    const handleChange = (selected) => {
        setSelectedFilterOptions(selected);
    };

    const handleApply = () => {
        onApply(selectedFilterOptions);
        onRequestClose();
    };

    const reset = () => {
        setSelectedFilterOptions([]);
        onReset();
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            onRequestClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.modal} ref={filterRef}>
            <div className={styles.header}>
                <div id="filter-dialog-title">Apply Filter</div>
                <img className={styles.close} src={IMAGES.close} onClick={onRequestClose} alt="close" />
            </div>
            <div className={styles.multiselect}>
                <div className={styles.label}>{label}</div>
                <DropDown
                    options={uniqueOptions}
                    placeholder="Select"
                    onChange={handleChange}
                    value={selectedFilterOptions}
                    hasError={false}
                    menuIsOpen={isOpen}
                />
            </div>
            <div className={styles.footer}>
                <button onClick={reset} className={styles.closeButton}>Reset</button>
                <button onClick={handleApply} disabled={selectedFilterOptions.length === 0} className={`${styles.applyButton} ${selectedFilterOptions.length === 0 ? styles.disabled : ''}`}>Apply</button>
            </div>
        </div>
    );
};

Filter.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    onApply: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    initialSelectedOptions: PropTypes.array
};

// export default Filter;

export const AppliedFilter = ({ selectedFilterOptions, onReset, setSelectedFilterOptions, selectedInvoicingPartyOptions,handleFilterApply, handleInvoicingFilterApply}) => {
    const handleRemove = (itemToRemove) => {
        console.log("selectedInvoicingPartyOptions",selectedInvoicingPartyOptions)
        console.log("selectedFilterOptions",selectedFilterOptions)
        const updatedOptions = selectedFilterOptions.filter(item => item.value !== itemToRemove.value);
        setSelectedFilterOptions(prevState => ({
          ...prevState,
          selectedFilterOptions: updatedOptions
        }));
        if (updatedOptions.length > 0) {
          handleFilterApply(updatedOptions);
        } else {
            if(selectedInvoicingPartyOptions?.length===0){
                reset();
            }
        }
      };

      const handleInvoicingRemove = (itemToRemove) => {
        const updatedOptions = selectedInvoicingPartyOptions.filter(item => item.value !== itemToRemove.value);
        setSelectedFilterOptions(prevState => ({
          ...prevState,
          selectedInvoicingPartyOptions: updatedOptions
        }));
        if (updatedOptions.length > 0) {
            handleInvoicingFilterApply(updatedOptions);
        } else {
            if(selectedFilterOptions?.length===0){
                reset();
            }
        }
      };
    
      const reset = () => {
        setSelectedFilterOptions(prevState => ({
          ...prevState,
          selectedFilterOptions: [],
          selectedInvoicingPartyOptions:[]
        }));
        onReset();
      };

    return (
        <div className={styles.parent}>
            <div className={styles.subparent}>
                <div className={styles.text}>Filters Applied:</div>
                {selectedFilterOptions?.length>0&&(
                <div className={styles.invoiced}>
                    <div className={styles.invoicedtext}>Invoiced Party:</div>
                    <div className={styles.invoicedData}>
                        {selectedFilterOptions?.map((item, index) => (
                            <div key={index} className={styles.invoicelabel}>
                                {item.label}
                                <img className={styles.cancel} src={IMAGES.close} alt="close"onClick={() => handleRemove(item)}/>
                            </div>
                        ))}
                    </div>
                </div>
                 )}
                {selectedInvoicingPartyOptions?.length>0&&(
                <div className={styles.invoiced}>
                    <div className={styles.invoicedtext}>Invoicing Party:</div>
                    <div className={styles.invoicedData}>
                        {selectedInvoicingPartyOptions?.map((item, index) => (
                            <div key={index} className={styles.invoicelabel}>
                                {item.label}
                                <img className={styles.cancel} src={IMAGES.close} alt="close"onClick={() => handleInvoicingRemove(item)}/>
                            </div>
                        ))}
                    </div>
                </div>
                )}
            </div>
            <div className={styles.resetfil} onClick={reset}>
                <img className={styles.reseticon} src={IMAGES.reseticon} alt="reset" />
                Reset
            </div>
        </div>
    );
};

AppliedFilter.propTypes = {
    selectedFilterOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    onReset: PropTypes.func.isRequired,
    setSelectedFilterOptions: PropTypes.func.isRequired,
};