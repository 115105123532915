import React, { useEffect, useState } from "react"
import { SubmitButton, LinkButton, Submit } from "../../components/button/buttons";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, useField } from 'formik';
import { logOut, enrollSecondFactor, complete2FAEnrollment, enableRecaptcha } from "../../authActions/authActions";
import { TextInput } from "../../components/input/inputs";
import styles from "./mfa.module.css"
import en from 'react-phone-number-input/locale/en'
import CountryCode  from "../../components/dropDown/CountryCode"
import { SIGN_IN_ERROR_MESSAGES } from "../../authActions/constants";
import { getCountryCallingCode } from 'react-phone-number-input/input'


let obj = {
    width: 210,
    marginTop:5,
    marginRight:10
}
const FieldWrapper = ({ children }) => {
    return <div className={styles.fieldContainer}>{children}</div>;
};

const TwoFactorAuth = ({ onSendOTP,isSubmitSendOTP }) => {
    
    const [phone, setPhone] = useState("")
    const [code, setCode] = useState("")
    const [country, setCountry] = useState()
    //enableRecaptcha()
    return (
        <div className={styles.mfaContainer}>
            <h2 className={styles.title}>Enable Two Factor Authentication</h2>
            {/* <label>You need to enable Two-Factor authentication before you can access the Admin Portal</label> */}
            <span className={styles.description}>For security purposes, we have implemented two factor authentication to login to the IPX portal.</span>

            <span className={styles.description}>Please provide your phone number below, and we will send a verification code to your phone via SMS.</span>
            <div className={styles.phoneContainer}>
            <CountryCode
            id="countryCode"
            className={styles.countryCode}
            labels={en}
            value={country}
            onChange={setCountry}/>
                <TextInput
                    label="Number"
                    name="phone"
                    type="number"
                    style={styles.phoneInput}
                    placeholder="Enter mobile number"
                    onChange={(event) => {
                        setPhone(event.target.value)
                    }}
                />               
            </div>
            <span className={styles.message}>Do not prefix a 0 before the mobile number.</span>
            <Submit                  
                style={styles.submit}
                title={"Send Code"}
                isSubmitting={isSubmitSendOTP}                
                disabled={(!country || phone.trim().length ==0)}  // || phone.toString().length < 10 || phone.toString().length > 15
                onClick={() => {
                   let _countryCode = `+${getCountryCallingCode(country)}`; //CUP-667
                    onSendOTP(`${_countryCode}${phone}`)
                }} />
        </div>
    );
}

const OTPVerification = ({ onPhoneReset, phone, onVerify,isSubmitVerifyOTP }) => {
    const [otp, setOTP] = useState("")
    const [deviceName, setDeviceName] = useState("My Phone")
    return (
        <div className={styles.mfaContainer}>
            <h2>Verification Code</h2>
            {/* <label>You need to enable Two-Factor authentication before you can access the Admin Portal</label> */}
            <span className={styles.description2}>{`Please enter the code you have received on ${phone}.`}</span>

            <span className={styles.description2}>You will receive this via SMS.</span>
            <div className={styles.otpContainer}>
                <input
                    className={styles.otpInput}
                    type={"number"}
                    placeholder="Enter verification code"
                    onChange={(event) => {
                        setOTP(event.target.value)
                    }} />
                <input
                    className={styles.otpInput}
                    placeholder="Name your device eg: My iPhone"
                    onChange={(event) => {
                        setDeviceName(event.target.value)
                    }} />
            </div>
            {/*<SubmitButton
                onClick={() => {
                    onVerify(otp, deviceName)

                }}>Verify</SubmitButton>*/}

            <Submit                  
                style={styles.submit}
                title={"Verify"}
                isSubmitting={isSubmitVerifyOTP}                
                disabled={(!otp)} 
                onClick={() => {
                    onVerify(otp, deviceName)
                }}
            />
            <LinkButton
                title={"Incorrect phone number? Click here to re-enter."}
                onClick={onPhoneReset} />
        </div>
    );
}


const MultifactorEnabler = (props) => {
    const [detailsCaptured, setDetailsCaptured] = useState(false)
    const [phone, setPhone] = useState("")
    const [verificationId, setVerificationId] = useState("");
    const [isSubmitSendOTP,setIsSubmitSendOTP]=useState(false);
    const [isSubmitVerifyOTP,setIsSubmitVerifyOTP]=useState(false);

    useEffect(() => {
        return () => {
            window.recaptchaVerifier2FA = undefined;
        };
      }, []);

    const onSendOTP = (phone) => {
        setPhone(phone)
        setIsSubmitSendOTP(true);
        enrollSecondFactor(phone).then((verifyId) => {
            setVerificationId(verifyId)
            setDetailsCaptured(true);
            setIsSubmitSendOTP(false);
        }).catch((error) => {
            setIsSubmitSendOTP(false);
            setVerificationId("")
            setDetailsCaptured(false);
            if (error.code === "auth/invalid-phone-number") {
                alert(SIGN_IN_ERROR_MESSAGES.INVALID_PHONE_NUMBER);
            } else if (error.code === "auth/too-many-requests") {
                alert(SIGN_IN_ERROR_MESSAGES.TOO_MANY_REQUEST);
            } else if (error.code === "second-factor-already-in-use") {
                alert(SIGN_IN_ERROR_MESSAGES.ALREADY_2FA_ENROLLED);
            }else if(error.code==="auth/requires-recent-login"){
                alert(SIGN_IN_ERROR_MESSAGES.REQUIRES_RECENT_LOGIN);
                logOut();
            }else if(error.code==="auth/invalid-app-credential"){
                alert(SIGN_IN_ERROR_MESSAGES.INVALID_APP_CREDENTIALS);
            }else {
                alert(SIGN_IN_ERROR_MESSAGES.RECAPTCHA_ISSUE);
            }
            console.log("error", error);
        })
    }

    const onVerifyOtp = async (otp, deviceName) => {
        try {
            setIsSubmitVerifyOTP(true);
            const result = await complete2FAEnrollment(verificationId, otp, deviceName)
            setIsSubmitVerifyOTP(false);
            logOut()
                .then((res) => {
                    navigate("/login");
                    //TBD
                    alert("Verification code verified. Please login to continue")
                    /*setTimeout(() => {
                        window.location.reload();
                    }, 6000)*/
                })
                .catch((error) => {
                });
        } catch (error) {
            console.log("error",error);
            setIsSubmitVerifyOTP(false);
            if(error.code="auth/invalid-verification-code"){
                alert(SIGN_IN_ERROR_MESSAGES.INVALID_VERIFICATION_CODE);
            }else if (error.code === "auth/missing-verification-id") {
                alert(SIGN_IN_ERROR_MESSAGES.RECAPTCHA_ISSUE);
            }else{
                alert(SIGN_IN_ERROR_MESSAGES.RECAPTCHA_ISSUE);
            }
        }
    }

    const signInWithDifferentUser = () => {
        logOut();
           
    }

    const navigate = useNavigate();
    return (
        <div className={styles.root}>
            <div className={styles.container}>
            <div id="captcha-container-2fa"></div>
                {
                    detailsCaptured ?
                        <OTPVerification
                            phone={phone}
                            onPhoneReset={() => {
                                setDetailsCaptured(false)
                            }}
                            onVerify={onVerifyOtp} isSubmitVerifyOTP={isSubmitVerifyOTP}/>
                        :
                        <TwoFactorAuth
                            onSendOTP={onSendOTP} isSubmitSendOTP={isSubmitSendOTP}/>
                }
                <LinkButton className={styles.resetLink}
                    title={"Click here to sign in with a different user"}
                    onClick={signInWithDifferentUser}
                />
            </div>
        </div>
    );
}

export default MultifactorEnabler;