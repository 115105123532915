import React, { useEffect, useRef, useState } from "react";
import { Container, ChildContainer } from "../../../../components/Container/Containers";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../../../../components/sidebar/Sidebar";
import { ServiceListContainer, StatusContainer, ServiceDetailContainer, ServiceHeader, ServiceMiddleDiv, ServiceDescription, DateOfSubmission, ServiceName, ServiceFooter, IconsContainer } from "../../../../components/list/list";
import styles from "./NewRequestViewer.module.css";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import comment from '../../../../assets/images/comment.png'
import ammendService from "../../../../assets/images/edit.png";
import Comment from "../../comment/comment.container";
import { Search } from "../../../../components/search/search";
import DropDown from "../../../../components/dropDown/DropDown";
import { Loader } from "../../../../components/loader/Loader";
import ServiceStatus from "../../serviceStatus/serviceStatus.container";
import { MY_SERVICE_TABS, SERVICE_STATUS_OPTIONS } from "../../../../utils/constants/serviceConstants";
import SectionGuard from "../../../../hoc/guards/SectionGuard";
import CAN from '../../../../casl/can';  
import {Action , Resource} from '../../../../casl/constants'; 
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyContainer } from "../../../dashboard/graphs/component/component";
import { isString } from "../../../../utils/utils";
import Overlay from "../../../dashboard/graphs/overlay/Overlay";
import { Formik, useField, Form, Field } from 'formik';
import moment from 'moment';
import * as Yup from "yup";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../components/label/Labels";
import * as XLSX from 'xlsx';
import IMAGES from "../../../../assets/images/images";
import { DIAMETER, SIGTRAN, TRANSPORT, DNS, GRX } from "../../../../utils/constants/serviceConstants";
import {formatDate} from "../../../../utils/arrayUtils";
const getAllFormattedData = (data) => {
  let formattedData = [];
  data.forEach(item => {
    let statObj = SERVICE_STATUS_OPTIONS.find(({ value }) => value === item?.status);
    let fData = {};
    fData.requestId = item?.requestId;
    fData.createdBy = isString(item?.createdBy) ? item?.createdBy : item.createdBy?.name;
    fData.createdAt = item?.createdAt ? formatDate(item.createdAt) : '';
    fData.service = item?.service;
    fData.requestId = item?.requestId;
    fData.customer = item?.organization;
    fData.location = item?.locationDetails?.serviceRegion?.label;
    fData.country = item?.locationDetails?.serviceCountry?.label;
    fData.status = statObj?.label;
    formattedData.push(fData);
  });
  return formattedData;
}
const getExcelHeaderBySearvice = (serviceName) => {
  switch (serviceName) {
    case DIAMETER:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'Avg Monthly DTR Volumes',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
    case SIGTRAN:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'Type',
        'Avg Monthly MSU Volumes',
        'Top Destinations',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
    case GRX:
    case TRANSPORT:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'Allocated Capacity',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
    case DNS:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'How Many DNS',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
    default:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'Avg Monthly DTR Volumes',
        'How Many DNS',
        'Type',
        'Avg Monthly MSU Volumes',
        'Top Destinations',
        'Allocated Capacity',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
  }

}
const exportToExcel = (data, fileName, sheetName) => {
  const services = [DIAMETER, SIGTRAN, TRANSPORT, DNS, GRX];
  let headers = [];
  const workbook = XLSX.utils.book_new();
  let formattedDataByService = {};
  let dataByServices = data.reduce(function (accObj, currentObj) {
    if (services.includes(currentObj?.service.trim())) {
      accObj[currentObj?.service] = accObj[currentObj?.service] || [];
      accObj[currentObj?.service].push(currentObj);
    }
    return accObj;
  }, {});

  Object.keys(dataByServices).forEach((services) => {
    formattedDataByService[services] = [];
    Object.keys(dataByServices[services]).forEach((service) => {
      let item = dataByServices[services][service];        
      let statObj = SERVICE_STATUS_OPTIONS.find(({ value }) => value === item?.status);
      let fData = {};
      fData.requestId = item?.requestId;
      fData.createdBy = isString(item?.createdBy) ? item?.createdBy : item.createdBy?.name;
      fData.createdAt = item?.createdAt ? formatDate(item.createdAt) : '';
      fData.service = item?.service;
      if (services == DIAMETER) {
        fData.avg_monthly_dtr_volumes = item?.service_option.avg_monthly_dtr_volumes;
      }
      if (services == DNS) {
        fData.dns_number = item?.service_option.dns_number;
      }
      if (services == SIGTRAN) {
        fData.type = item?.service_option.type ? item.service_option.type.toString() : '';
        fData.avg_monthly_msu_volumes = item?.service_option.avg_monthly_msu_volumes;
        fData.top_destinations = item?.service_option.top_destinations;
      }
      if (services == TRANSPORT || services == GRX) {
        fData.allocated_capacity = item?.service_option.allocated_capacity;
      }
      fData.customer = item?.organization;
      fData.location = item?.locationDetails?.serviceRegion?.label;
      fData.country = item?.locationDetails?.serviceCountry?.label;
      fData.status = statObj?.label;
      formattedDataByService[services].push(fData);
    });
  });    

  let allDataHeaders = [[
    'Request Id',
    'Requestor Name',
    'Submitted on',
    'Service',
    'Organisation',
    'Region',
    'Country',
    'Status' 
  ]];
  const worksheet = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(worksheet, allDataHeaders);
  XLSX.utils.sheet_add_json(worksheet, getAllFormattedData(data), { origin: 'A2', skipHeader: true });
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);


  Object.keys(formattedDataByService).forEach((service) => {
    headers = getExcelHeaderBySearvice(service);
    var worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, headers);
    XLSX.utils.sheet_add_json(worksheet, formattedDataByService[service], { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(workbook, worksheet, service);
  });
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${fileName}.xlsx`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(url);
};


const ServiceStatusIcon = (props) => {
  return (
    <img className={styles.ammendServiceIcon} src={ammendService} onClick={props.openServiceStatusForm}  />
  );
}


const ServiceList = ({ data, key, hide, showCommentPopup, showServiceStatusPopup, isInternalUser, ...props }) => { 
  const navigate = useNavigate();

  const goToServiceDetails = (requestId) => {
    navigate(`/demands/${requestId}`);
  }
  return (
    <li id={key} className={`${hide} ${styles.listCardMmargin}`}   >
      <ServiceListContainer>
        {/* <StatusContainer statusName={data.status} /> */}
        <ServiceDetailContainer requestId={data.id} statusName={data.status} >
          <ServiceHeader>
           <div className={styles.header} onClick={()=>goToServiceDetails(data.id)}>
            <ServiceName displayId={data.requestId}/>
            <StatusContainer statusName={data.status} />
           </div>
            <div className={styles.headeright}>
              {/* <DateOfSubmission date={formatSecondsToDate(data.createdAt)} /> */}
            <IconsContainer>
              {/* <SectionGuard canAccess={CAN(Action.CREATE_COMMENT, Resource.DEMANDS)}>
              <CommentBox openCommentForm={(event) => {
              return showCommentPopup(event,data);
              }} />
              </SectionGuard> */}
              {/* <SectionGuard canAccess={CAN(Action.STATUS_UPDATE, Resource.DEMANDS)}>
                <ServiceStatusIcon openServiceStatusForm={(event) => {
                  return showServiceStatusPopup(event,data);}}
                 />
             </SectionGuard> */}
            </IconsContainer></div>
          </ServiceHeader>
          <ServiceMiddleDiv>
            {/* <ServiceDescription description={data.comment} /> */}
          </ServiceMiddleDiv>
          <ServiceFooter companyName={data.organization} country={data?.locationDetails?.serviceCountry?.label} location={data?.locationDetails?.serviceRegion?.label} requestId={data.id} createdBy={isString(data.createdBy)?data.createdBy:data.createdBy?.name} createdByemail={isString(data.createdBy)?data.createdBy:data.createdBy?.email} displayId={data.requestId} isInternalUser={isInternalUser} serviceName={data.service} date={formatDate(data.createdAt)}/>
        </ServiceDetailContainer>
      </ServiceListContainer>
      {/* <div className={styles.collapsibleContainer}>
      <div className={styles.collapsible}>
      <div className={styles.header}>
          <div>
            <span className={styles.SRid} onClick={()=>goToServiceDetails(data.id)}>
              {data.requestId}
            </span>
            <span
              className={`${styles.status} ${styles[data?.status]}`}>
              {data.status}
            </span>
          </div>
        </div>
      </div>
      </div> */}
    </li>
  );
}

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const SelectField = ({ title, options, ...props }) => {
  //let _options = options.map((item) => ({ value: item.value, label: item.name }));
  //console.log("---optionsssss in dropdown--",_options)
  const [field, meta, helpers] = useField(props);
  
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div>
      <Label text={title} />
      </div>
      <DropDown
        options={options}   
        customStyles={props.customStyles}
        onChange={({ label, value }) => {
          helpers.setValue(value);             
        }}
        value={
          (options && field.value)
            ? options.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
        isDisabled={props.isDisabled}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};
const SelectedFilters=({selectedFilters,...props})=>{  
  let statusName = selectedFilters?.status?selectedFilters?.status=='All'?'ALL':SERVICE_STATUS_OPTIONS.find(({value}) => value === selectedFilters?.status)?.label?.toUpperCase():'NOT SELECTED';
  return(
      <div className={styles.selectedFilters}> 
      <div className={styles.labelShowFilter}>
            <span>Showing results for &nbsp;</span>
      </div>        
      {
        selectedFilters?.user_type == USER_TYPE.INTERNAL ?
          <div className={styles.selectedItem}>
            <span className={styles.label}>Customer</span><span className={styles.value}>{selectedFilters?.customer ? selectedFilters?.customer?.toUpperCase() : "NOT SELECTED"}</span>
          </div> : <></>
      }         
      <React.Fragment>             
          <div className={styles.selectedItem}>
              <span className={styles.label}>Service</span><span className={styles.value}>{selectedFilters?.serviceType ? selectedFilters?.serviceType?.toUpperCase() : "NOT SELECTED"}</span>
          </div> 
          <div className={styles.selectedItem}>
              <span className={styles.label}>Status</span><span className={styles.value}>{statusName}</span>
          </div>
      </React.Fragment>
         
    </div>
  )
}
const sortArray =(array)=>{
  if (array.length > 0){
  const newArray=JSON.parse(JSON.stringify(array)); // No change in original array
  return newArray.sort((a,b)=>a?.toUpperCase().localeCompare(b?.toUpperCase()));  // Ascending alphabetical
  }
  return [];
}
const FilterContainer = ({ defaultFilters,resetFilters,filterData, customerList, serviceList, statusList, setStatusFilter, setCustFilter, setServiceFilter, ...props }) => {
  let obj = {
    width: "auto"
  }
  const statusOptions=[{ label: "ALL", value: "All" },...statusList];  
  const serviceTypeOptions=[{ label: "ALL", value: "All" },...serviceList];
  let _customerList=customerList?.map((item) => ({ label: item?.cpName, value: item?.cpName  }))
  const customerOptions=[{ label: "ALL", value: "All" },..._customerList];
 
  let _initialValues = defaultFilters; 
  
  const handleServiceTypeChange=(value)=>{
      
  }
  const handleStatusChange=(value)=>{
      
  }
  const handleCustomerChange=(value)=>{
      
  }

  return (
      <div>
          <Formik
              initialValues={_initialValues}              
              onSubmit={async (values) => {
                let queryString = '';
                queryString += values?.customer?"&customer="+encodeURIComponent(values?.customer):'';
                queryString += values?.serviceType?"&serviceType="+encodeURIComponent(values?.serviceType):''; 
                queryString += values?.status?"&status="+encodeURIComponent(values?.status):''; 
                  filterData('filter', (queryString));
                  setStatusFilter(values?.status);
                  setCustFilter(values?.customer);
                  setServiceFilter(values?.serviceType);                  
              }}
          >
              {({ isValid, dirty, values,setFieldValue }) => (
                  <Form>
                 
                     <React.Fragment>
                         {
                             defaultFilters.user_type===USER_TYPE.INTERNAL ?
                                 <React.Fragment>
                                     <SelectField
                                        title={"Customer"}
                                        name="customer"
                                        options={customerOptions}
                                        customStyles={obj}
                                        onChange={handleCustomerChange}
                                    />
                                 </React.Fragment> : <></>
                         }
                     </React.Fragment>                   
                        
                 <SelectField
                     title={"Service"}
                     name="serviceType"
                     options={serviceTypeOptions}
                     customStyles={obj}
                     onChange={handleServiceTypeChange}
                   
                 />
                  <SelectField
                     title={"Status"}
                     name="status"
                     options={statusOptions}
                     onChange={handleStatusChange}  
                     customStyles={obj}                  
                 />
                 <div className={styles.formikBtnContainer}>
                   <button className={styles.formikBtnClear} onClick={resetFilters}>Reset</button>
                    <button  type="submit" className={styles.formikBtnSubmit}>Apply</button>
                 </div>
             </Form>
              )}
          </Formik>

      </div>
  )
}
const ServicesList = ({ data,fetchData,hasNext,isAmendMode, showCommentPopup, statusOptions, filterData, isLoading, getSearchKeyValue, showServiceStatusPopup, isInternalUser,onKeyDownHandler, props, resetFilters, defaultFilters, childRef, services, customerList, searchKeyValue, setStatusFilter, setCustFilter, setServiceFilter, filterSearch, handleExportToExcel}) => {
 
  return (
<>
      <SearchBar statusOptions={statusOptions} filterData={filterData} getSearchKeyValue={getSearchKeyValue} onKeyDownHandler={onKeyDownHandler} resetFilters={resetFilters} defaultFilters={defaultFilters} childRef={childRef} services={services} customerList={customerList} searchKeyValue={searchKeyValue} setStatusFilter={setStatusFilter} setCustFilter={setCustFilter} setServiceFilter={setServiceFilter} filterSearch={filterSearch} handleExportToExcel={handleExportToExcel}/>    
      
    <div className={styles.listContainer}>
      <ul className={styles.servicesList}>
        {isLoading && <Loader />}
        {
            !isLoading && data.length==0? (
            <EmptyContainer>No record found</EmptyContainer>
        ) : (
          <InfiniteScroll
          dataLength={data?.length} //This is important field to render the next data
          next={() => {
            fetchData();
          }}
          hasMore={hasNext}
          loader={
              <Loader />
          }
        >
          {
           data.map((serviceList) => {
            return (
              <ServiceList
                key={serviceList.requestId}
                data={serviceList}               
                showCommentPopup={showCommentPopup}
                showServiceStatusPopup={showServiceStatusPopup}
                isInternalUser={isInternalUser}
              />
            );
          })
          }
        </InfiniteScroll>

         
        )}
      </ul>
    </div>
</>
  );
}


const CommentBox = (props) => { 
  return (
    <img className={styles.commentIcon} src={comment} onClick={props.openCommentForm} />
  );
}

const SearchBar = ({ statusOptions, filterData, getSearchKeyValue,onKeyDownHandler,resetFilters, defaultFilters, childRef, services, customerList, searchKeyValue, setStatusFilter, setCustFilter, setServiceFilter, filterSearch, handleExportToExcel}) => {
  let obj = {
    width: '185px',
  }
  const inputStyle = `${styles.inputContainer} `;
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.searchContainer}>
          <Search 
           placeholder={"Search"}
           style={inputStyle}
           filterData={filterData}
           getSearchKeyValue={getSearchKeyValue}
           value={searchKeyValue}
           onKeyDown={onKeyDownHandler}
          />        
        </div>
        <div className={styles.filterContainer}>
          <Overlay ref={childRef} className={styles.applyFilterBtn} >
            <FilterContainer 
              defaultFilters={JSON.parse(JSON.stringify(defaultFilters))}
              resetFilters={resetFilters}
              filterData={filterData}
              serviceList={services?.length?services:[]}
              customerList={customerList?.length?customerList:[]}
              filter={defaultFilters}
              statusList={statusOptions?.length?statusOptions:[]}
              setStatusFilter={setStatusFilter}
              setCustFilter={setCustFilter}
              setServiceFilter={setServiceFilter}
              />
          </Overlay>
        </div>
        <SectionGuard canAccess={CAN(Action.DOWNLOAD, Resource.DEMANDS)}>
        <div>
        <button className={styles.download} onClick={handleExportToExcel}>Download <span><img className={styles.downloadlogo} src={IMAGES.download}/></span></button> 
        </div>
        </SectionGuard>   
      </div>
      {filterSearch === false?<></>:
      <div className={styles.selectedFilterContainer}>
        <SelectedFilters selectedFilters={defaultFilters}/>
      </div>}
      </>
  );
};

const NewRequestViewer = (props) => {
  let servicesList = props.servicesList; 
  //console.log("servicelist------->", servicesList);
  let [serviceData, setServiceData] = useState({});  
  let [serviceStatus, setServiceStatus] = useState('');  
  let [statusFilterValue, setStatusFilterValue] = useState('');
  let [searchKeyValue, setSearchKeyValue] = useState('');
  let [custFilterValue, setCustFilterValue] = useState('');
  let [serviceFilterValue, setServiceFilterValue] = useState('');
  let [filterSearch, setFilterSearch] = useState(false);
  let [filterSearchValue, setFilterSearchValue] = useState('');
  
  const childRef = useRef();

  let defaultFilters={    
     serviceType: serviceFilterValue,
     customer: custFilterValue,  
     status: statusFilterValue,  
     user_type:props.myRights?.userType,    
 }

  useEffect(() => {
    props.getServicesList();
    props.getCustomers();
    props.getServices();
  }, []);

  useEffect(() => {   
    if (filterSearch == true){
      setSearchKeyValue('');
    } else {
      setFilterSearchValue('');
    }
    
  }, [filterSearch]);

  const filterData = (label, value) => {      
    let filter, searchKey = '';   
     if (label =='searchKey'){
      searchKey = searchKeyValue;     
      setFilterSearch(false)
     }
     if (label =='filter'){
      setFilterSearch(true)
      filter = value;  
      setFilterSearchValue(value);
      
      childRef.current.handleClick(); /// TO CLOSE APPLY FILTER POP UP     
     }
       
     if (label =='searchKey'){
      setStatusFilter('');
      setCustFilter('');
      setServiceFilter('');
      setSearchKeyValue(searchKey);      
     }     
     let next= null;
     //console.log(filt)
     props.getServicesList(filter, searchKey,next)
  }
const getSearchKeyValue  = (e) => {
  setSearchKeyValue(e.target.value);
}

  const closeCommentsSidebar = () => {
    props.closeCommentForm();
  }
  

    //this.state.setServiceData({serviceDetails}); // open blank form 
  const showCommentPopup=(event,data)=>{  
    event.stopPropagation();  
    setServiceData({data});    
    props.openCommentForm();
  }
  const showServiceStatusPopup=(event,data)=>{  
    event.stopPropagation(); 
    setServiceData({data});
    setServiceStatus(data.status);
    props.openServiceStatusForm();
  }

  const fetchData=()=>{
    if(props.next){     
      props.getServicesList(filterSearchValue,searchKeyValue,props.next);
    }
  }

  const onKeyDownHandler = (e) => {   // filter data on enter event
    if (e.key === "Enter"){
      let next= null;
      setFilterSearch(false)
      setStatusFilter('');
      setCustFilter('');
      setServiceFilter('');
      setFilterSearchValue('');      
      props.getServicesList('', searchKeyValue,next)       
    }
  };

  const resetFilters=()=>{
    setStatusFilter('');
    setCustFilter('');
    setServiceFilter('');
    setFilterSearch(false)
    childRef.current.handleClick();
    filterData('','',null);
}
const setStatusFilter = (value) => {
  setStatusFilterValue(value);
}
const setCustFilter = (value) => {
  setCustFilterValue(value);
}
const setServiceFilter = (value) => {
  setServiceFilterValue(value);
}

const handleExportToExcel = () => { 
  let fileNameStartWith=props.myRights?.userType==USER_TYPE.INTERNAL?"Demands":"My Demands" // dynamic tab name
  let qString = filterSearchValue+"&download="+encodeURIComponent(true); 
  props.downloadServicesList(qString,searchKeyValue).then((result) => {
    //console.log("result",result.downloadedData);
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear().toString().slice(-2)}`;
    exportToExcel(result.downloadedData, `${fileNameStartWith?.split(" ").join("")}-${formattedDate}`,fileNameStartWith); 
  })
  .catch((error) => {   
  }); 
};

const tabsInfoCustomer="This section provides an overview of all your submitted demands, including their current status and additional details. You can also add comments and attach additional documents, if any."
const tabsInfoInternal="This section provides an overview of all demands raised by customers. Additionally, you can add comments and update the status as needed."
  
  return (
    <React.Fragment>
      {/* <Sidebar
        isOpen={props.isCommentFormOpen}
        onClose={closeCommentsSidebar}
        headerTxt={"Add comment"}
      > */}
      <div className={`${styles.moduledescription} ${CAN(Action.LIST, Resource.DEMANDS) && CAN(Action.LIST, Resource.LIVE_SERVICES)?"":styles.marginfromtop}`}> <img  className={styles.descriptioncircle} src={IMAGES.infoCircle}/>{props.myRights?.userType === USER_TYPE.INTERNAL ? tabsInfoInternal : tabsInfoCustomer }</div>
      <Comment serviceData={serviceData}></Comment>
      {/* </Sidebar>     */}
      <ServiceStatus openFrom={"NewServiceViewer"} serviceData={serviceData} status={serviceStatus}></ServiceStatus>
      <ServicesList data={servicesList} fetchData={fetchData} hasNext={props.next} showCommentPopup={showCommentPopup} statusOptions={SERVICE_STATUS_OPTIONS} filterData={filterData} isLoading={props.isLoading} getSearchKeyValue={getSearchKeyValue} showServiceStatusPopup={showServiceStatusPopup} isInternalUser={props.myRights?.userType===USER_TYPE.INTERNAL?true:false} onKeyDownHandler={onKeyDownHandler} resetFilters={resetFilters} defaultFilters={defaultFilters} childRef={childRef} services={props.services} customerList={props.customers} searchKeyValue={searchKeyValue} setStatusFilter={setStatusFilter} setCustFilter={setCustFilter} setServiceFilter={setServiceFilter} filterSearch={filterSearch} handleExportToExcel={handleExportToExcel}/>
 
    </React.Fragment>
  )
};
export default NewRequestViewer;