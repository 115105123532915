import { FETCH_BROADCAST_NOTIFICATION_LIST } from "../../actions/actionTypes";

const initialState = {  
    broadCastnotification: [],
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {    
        case FETCH_BROADCAST_NOTIFICATION_LIST.REQUEST:
            return { ...state, loading: true };
        case FETCH_BROADCAST_NOTIFICATION_LIST.FAILURE:
            return { ...state, loading: false };
        case FETCH_BROADCAST_NOTIFICATION_LIST.SUCCESS:
            return { ...state, broadCastnotification: action.obj, loading: false };
        default:
            return state;
    }
};

export default reducer;