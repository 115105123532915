import CustomerDetail from "./CustomerDetail";
import {connect} from "react-redux";
import { getUser, resetUserList } from "../../../../redux/actions/userManagement/userViewer.action";
import { getPredefinedRoles } from "../../../../redux/actions/userManagement/functionalRoles.action";
import { updateUserPermissions } from "../../../../redux/actions/userManagement/updatePermissions.action";
import { getCustomerDetails } from "../../../../redux/actions/userManagement/customer.action";
import { openAlertPopup, closeAlertPopup } from "../../../../redux/actions/Alert/alert.action";
import { SubmitActiveInactive } from "../../../../redux/actions/userManagement/userActiveInavtive.action";
import { SubmitFincaId } from "../../../../redux/actions/finca/submitFincaId.action";
import { getFincaTadig } from "../../../../redux/actions/finca/fincaTadig.action";


const mapStateToProps = (state) => {
    return {
        userList:state?.userViewer?.data,
        customer:state?.CustomerDetail?.customer,
        isLoadingCustomer:state.CustomerDetail.loading,
        isLoading:state.userViewer.isLoading,
        next:state.userViewer.next,
        functionalRoles:state.functionalRoles.roles,
        myRights:state.myRights.myRights,
        accountManagerList: state.onboardingRequest.accountMManagerList.data,
        fincaTadig : state.fincaTadig
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (type,custID,searchKey,next=null) => {
            return dispatch(getUser(type,custID,searchKey,next))
        },
        getDetails: (customerID) => {
            return dispatch(getCustomerDetails(customerID))
        },

        getPredefinedRoles: (filter) => {
            return dispatch(getPredefinedRoles(filter))
        },
        updateUserPermissions: (payload,id) => {
            return dispatch(updateUserPermissions(payload,id))
        },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
        },
        closeAlertPopup: () => {
            return dispatch(closeAlertPopup())
        },
        resetUserList:()=>{
            return dispatch(resetUserList())
        },
        SubmitActiveInactive:(payload,id)=>{
            return dispatch(SubmitActiveInactive(payload,id))
        },
        SubmitFincaId:(payload,requestId)=>{
            return dispatch(SubmitFincaId(payload,requestId))
        },
        getFincaTadig: () => {
            return dispatch(getFincaTadig())
          },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);

