import React, { useEffect, useRef, useState } from "react";
import styles from "./component.module.css";
import DropDown from "../../../components/dropDown/DropDown";
import { CustomCalendar } from "../component/CustomCalendar";
import { OPTIONS } from "../../../utils/constants/fincaConstant";
import IMAGES from "../../../assets/images/images";

let obj = {
  width: "auto"
};

export const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

export const SelectField = ({
  title,
  options,
  onChange,
  isDisabled = false,
  isMandatory = true,
  value,
  lastGenratedInvoice,
  selectRef,
  setLastGenratedInvoice,
  ...props
}) => {
  const [selectValue, setSelectValue] = useState(value || { label: "", value: "" });
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (lastGenratedInvoice?.lastinvoice) {
      setSelectValue({ label: "", value: "" });
    }
    if(!lastGenratedInvoice?.lastinvoice && lastGenratedInvoice?.custom){
      setSelectValue({ label: "", value: "" });
    }
  }, [lastGenratedInvoice]);

  let _options = options.map((item) => {
    return {
      value: item.value,
      label: item.label
    };
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDropDownClick = (event) => {
    event.preventDefault();
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <FieldWrapper>
      <div ref={dropdownRef} onClick={handleDropDownClick} className={`${lastGenratedInvoice?.months?styles.wrapper:""}`}>
        <DropDown
          ref={selectRef}
          options={_options}
          customStyles={obj}
          // onChange={({ label, value }) => {
          //   setSelectValue({ label, value });
          //   onChange(label, value);
          // }}
          // className={`${lastGenratedInvoice?.months?styles.focusfield:""}`}
          onChange={({ label, value }) => {
            const formattedLabel = `Month: ${label}`;
            setSelectValue({ label: formattedLabel, value });
            onChange(formattedLabel, value);
          }}
          value={selectValue.value !== "" ? selectValue : ""}
          placeholder={props.placeholder}
          active={lastGenratedInvoice?.months}
        />
      </div>
    </FieldWrapper>
  );
};

export const FieldWrappers = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

export const TextField = ({
  label,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  value,
  isMandatory,
  disabled,
  handleCustomDateSelect,
  lastGenratedInvoice,
  setLastGenratedInvoice,
  ...props
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectValue, setSelectValue] = useState(value || { label: "", value: "" });
  useEffect(() => {
    if (lastGenratedInvoice?.lastinvoice) {
      setSelectValue({ label: "", value: "" });
    }
    if(!lastGenratedInvoice?.lastinvoice && lastGenratedInvoice?.months){
      setSelectValue({ label: "", value: "" });
    }
  }, [lastGenratedInvoice]);
  const calendarActionHandler = {
    onApply: (selectedDates, customLabel) => {
      setSelectValue({ label: customLabel, value: selectedDates });
      handleCustomDateSelect(selectedDates);
      setShowCalendar(false);
    },
    setSelectValue: setSelectValue,
    setShowCalendar: setShowCalendar,
    // setSelectValue: setSelectValue,
  };

  const extractYearFromValue = (value) => {
    if (Array.isArray(value) && value.length > 0) {
      return parseInt(value[0]?.split("-")[0], 10);
    } else if (typeof value === "string" && value.length > 0) {
      return parseInt(value?.split("-")[0], 10);
    }
    return new Date().getFullYear();
  };

  const determineSelectionType = (value) => {
    if (Array.isArray(value)) {
      return value.length === 1 ? OPTIONS.SINGLE : OPTIONS.MULTIPLE;
    }
    return OPTIONS.SINGLE;
  };

  return (
    <FieldWrappers>
      {lastGenratedInvoice?.custom?<img className={styles.selectedarrow} src={IMAGES.selectedarrow} alt="arrow"/>:<img className={styles.arrowdown} src={IMAGES.arrowdown} alt="arrow"/>}
      <input
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={() => setShowCalendar(true)}
        value={selectValue.value===""?"":selectValue.label}
        className={`${styles.textInput} ${lastGenratedInvoice?.custom?styles.focusfield:""}`}
        disabled={disabled}
      />
      {showCalendar && (
        <CustomCalendar
          actions={calendarActionHandler}
          initialValues={{
            selectedYear: extractYearFromValue(selectValue.value),
            selectedMonths: selectValue.value,
            selectionType: determineSelectionType(selectValue.value)
          }}
        />
      )}
    </FieldWrappers>
  );
};