import React, { useEffect, useId, useState } from "react"
import IMAGES from "../../../assets/images/images"
import styles from "./invoiceDetails.module.css"
import { Sidebar } from "../../../components/sidebarInv/Sidebar"
import { EditButton } from "../../../components/button/buttons"
import { downloadPDF } from "../../../utils/base64ToPdf"
import currencySymbol from "../../../utils/constants/currencySymbol"

const ExpandableArea = ({ children }) => {
  const [expanded, setExpanded] = useState(false)

  if (!expanded) {
    return (
      <div className={styles.expanded}>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(true)}
        >
          <span className={styles.view}>View All</span>
          <img src={IMAGES.arrowdown} width={24} height={24} alt="down arrow" />
        </button>
      </div>
    )
  }

  return (
    <div className={styles.expanded}>
      {children}
      <div>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(false)}
        >
          <span className={styles.view}>View Less</span>
          <img src={IMAGES.arrowup} width={24} height={24} alt="up arrow" />
        </button>
      </div>
    </div>
  )
}

const InvoiceDetails = (props) => {
  const { invoiceRecord, closeSidebar } = props.invoiceDetailProps
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(true)
  const getInvoiceDetails = (invoiceRecord) => {
    const fincaId = invoiceRecord.fincaID
    const invoicePeriod = invoiceRecord.invoicePeriod
    props.getInvoiceDetails(invoicePeriod, fincaId)
  }
  const data = props.invoiceData
  useEffect(() => {
    getInvoiceDetails(invoiceRecord)
    return () => {
      props.clearInvoiceDetail();
  };
  }, [])

  // console.log(" *** invoiceRecord ", invoiceRecord)
  // console.log(" *** closeSidebar ", closeSidebar)

  const ButtonWrapper = ({ children }) => {
    return <div className={styles.buttonContainer}>{children}</div>
  }
  const close = () => {
    setShowInvoiceDetails(false)
    closeSidebar()
  }

  const Shimmer = () => {
    return (
      <div className={styles.shimmerWrapper}>
        <div className={styles.shimmer}></div>
      </div>
    );
  };

  const currencySym = currencySymbol[props.invoiceData?.currency]? currencySymbol[props.invoiceData?.currency]: props.invoiceData?.currency;

  return (
    <div>
      <Sidebar
        isOpen={showInvoiceDetails}
        // headerTxt={`${"Invoice"}`}
        onClose={close}
        sideNavStyle={styles.sideNavStyle}
      >
        <div className={styles.sidebarContent}>
          <div className={styles.detailContent}>
          <div className={styles.borderLayoutMain}>
            <div className={styles.topsection}>
              <div className={styles.invoiceHeader}>
                <div className={styles.invoiceHeaderText}>Invoice</div>
                <div className={styles.invoiceHeaderLogo}>
                  <img src={IMAGES.logo} />
                </div>
              </div>
              <div className={styles.sidebarRowSection}>
                <div className={styles.sidebarRow}>
                  <div className={`${props.isLoading?styles.sidebarRowContentloading:styles.sidebarRowContent}`}>
                    <span className={styles.label}>Invoice Date:</span>
                    {props.isLoading?<Shimmer/>:<span className={styles.value}>{data.documentDate ? data.documentDate : "---"}</span>}
                  </div>
                  <div className={`${props.isLoading?styles.sidebarRowContentloading:styles.sidebarRowContent}`}>
                    <span className={styles.label}>Invoice Period:</span>
                    {props.isLoading?<Shimmer/>:<span className={styles.value}>{data.invoicePeriod?data.invoicePeriod:"---"}</span>}
                  </div>
                </div>
                <div className={`${styles.sidebarRow} ${styles.bottompart}`}>
                  <div className={`${props.isLoading?styles.sidebarRowContentloading:styles.sidebarRowContent}`}>
                    <span className={styles.label}>Invoice Number:</span>
                    {props.isLoading?<Shimmer/>:<span className={styles.value}>{data.documentReference?data.documentReference:"---"}</span>}
                  </div>
                  <div className={`${props.isLoading?styles.sidebarRowContentloading:styles.sidebarRowContent}`}>
                    <span className={styles.label}>Invoice Due Date:</span>
                    {props.isLoading?<Shimmer/>:<span className={styles.value}>{data.documentDueDate?data.documentDueDate:"---"}</span>}
                  </div>
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles.subContent}>
                <div className={`${props.isLoading?styles.colContentloading:styles.colContent}`}>
                  <span className={styles.label}>To:</span>
                  {props.isLoading?<Shimmer/>:<span className={styles.value}>{data.invoicedPartyName?data.invoicedPartyName:"---"}</span>}
                </div>
                <div className={`${props.isLoading?styles.colContentloading:styles.colContent}`}>
                  <span className={styles.label}>From:</span>
                  {props.isLoading?<Shimmer/>:<span className={styles.value}>{data.invoicingPartyName?data.invoicingPartyName:"---"}</span>}
                </div>
              </div>
            </div>
            <div className={styles.amountSection}>
            <div className={styles.divider} />
            <div className={styles.totalamount}>
              <div className={`${props.isLoading?styles.amountloading:styles.amountContent} ${styles.amontmargin}`}>
                <span className={styles.label}>Amount:</span>
                {props.isLoading?<Shimmer/>:<div><span className={styles.currency}>{currencySym}</span><span className={styles.value}>{data.documentNetAmount?data.documentNetAmount:"---"}</span></div>}
               </div>
              {/* <div className={`${props.isLoading?styles.amountloading:styles.amountContent}`}>
                <span className={styles.label}>Gross Amount:</span>
                {props.isLoading?<Shimmer/>:<span className={styles.value}>{data.documentGrossAmount}</span>}
              </div> */}
            </div>
            <div className={styles.divider} />
          </div>
          </div>
          <div className={styles.downloadSection}>
            <div className={styles.downloadContent}>
              <div className={`${props.isLoading?styles.attach:""}`}>
                <img className={styles.fileIcon} src={IMAGES.attachmenticon} />
                {props.isLoading?<Shimmer/>:<span
                  className={styles.label}
                  // onClick={() => downloadPDF(data.fileAsBase64, data.fileName)}
                >
                  {data.fileName}
                </span>}
              </div>
              {!props.isLoading&&(<img className={styles.downloadIcon} src={IMAGES.downloadlogo} onClick={() => downloadPDF(data.fileAsBase64, data.fileName)} />)}
            </div>
          </div>
          </div>
          <footer className={styles.footer}>
            <hr className={styles.separator} />
            <ButtonWrapper>
              <EditButton
                className={styles.closeButton}
                style={`${styles.submitt} ${styles.closeButton}`}
                title={"Close"}
                onClick={(e)=>close()}
              />
            </ButtonWrapper>
          </footer>
        </div>
      </Sidebar>
    </div>
  )
}
export default InvoiceDetails

