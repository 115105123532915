import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, ChildContainer } from "../../../components/Container/Containers";
import styles from "./service.module.css";
import { CommentContainer, CommentCreator, CommentContent, AttachmentContainer, Attachment } from "../../../components/comment/comment";
import downloadlogo from '../../../assets/images/downloadlogo.png';
import attachmentlogo from '../../../assets/images/attachmentlogo.png';
import { SERVICE_OPTIONS, SERVICE_STATUS_OPTIONS } from "../../../utils/constants/serviceConstants";
import SideNav from "../../fileAttachment/FileAttachment";
import {
  FormFieldLabel as Label,
} from "../../../components/label/Labels";
import DropDown from "../../../components/dropDown/DropDown";
import { Banner } from "../../../components/banner/Banners";
import { Breadcrumbs } from "../../../components/breadcrumbs/Breadcrumbs";
import { formatSecondsToDate } from "../../../utils/dateTime";
import IMAGES from "../../../assets/images/images";
import { Sidebar } from "../../../components/sidebar/Sidebar";
import Comment from "../comment/comment.container";
import comment from '../../../assets/images/comment.png'
import { USER_TYPE } from "../../../utils/constants/userConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../components/loader/Loader";
import ServiceStatus from "../serviceStatus/serviceStatus.container";
import CAN from '../../../casl/can';  
import {Action , Resource} from '../../../casl/constants'; 
import SectionGuard from "../../../hoc/guards/SectionGuard";
import { isString } from "../../../utils/utils";
import { StatusContainer } from "../../../components/list/list";
import { formatDate } from "../../../utils/arrayUtils";
import { getLogo } from "../../../utils/constants/fileTypeLogo";
import SecondaryTooltip from "../../../components/secondaryToolTip/secondaryToolTip";

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};
let obj = {
  width: "300px"
};

// const ServiceComment = ({ data, key,onFileDownload,isDownloadStart}) => {
//   return (
//     <CommentContainer id={data.id}>
//       <CommentCreator creatorName={isString(data.createdBy)?data.createdBy:data.createdBy?.name} createdDate={formatSecondsToDate(data.createdAt)} />
//       <CommentContent comment={data.comment} />
//       <Attachments isDownloadStart={isDownloadStart} onFileDownload={onFileDownload} attachments={('files' in data)? data.files : []} />
//     </CommentContainer>
//   );
// }

// const Attachments = ({ attachments,onFileDownload,isDownloadStart }) => {

//   const downloadFile = (file, index,onDownload) => {
//      // const fileName="Attachment "+Number(index+1);
//       onDownload(file)
//     }
    
//   return (
//     <AttachmentContainer>
//       {attachments?.length>0&&(
//         <>
//               <div className={styles.attachmentHeader}>Attachments</div>
//       <div className={styles.attachmentContainer}>
//       {
//       // attachments.length == 0 ? (
//       //   <div>No Records Found</div>
//       // ) : (
//         attachments.map((attachment, index) => {
//           return (
//             <div className={styles.attachmentDiv} title={attachment?.originalFileName}>
//               <img className={styles.attachlogo} src={attachmentlogo} />
//               <div className={styles.attachmentName}>{attachment?.originalFileName}</div>
//               {
//                   isDownloadStart?
//                   <Loader loaderStyle={styles.fileDownloadLoader}/>:
//                   <img src={downloadlogo} className={styles.download} onClick={() => downloadFile(attachment, index,onFileDownload)} />
//               }
//             </div>
//           );
//         })
//       // )
//       }
//       </div>
//         </>
//       )}
//     </AttachmentContainer>
//   );
// }

const DetailContainer = ({ data, serviceOption, isDownloadStart,isInternalUser, onFileDownload }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const text = data?.comment ? data.comment.split('\n').map((line, index, arr) => {
    if (index === arr.length - 1) {
      return <span key={index}>{line}</span>;
    } else {
      return <div key={index}>{line}</div>;
    }
  }) : "---";

  const previewText = data.comment?.slice(0, 100);
  const newlineIndex = data.comment?.indexOf('\n');
  const newline = newlineIndex !== -1 ? data.comment?.slice(0, newlineIndex) : data.comment;
  const showViewMore = data.comment?.length > 100 ||  data.comment?.includes('\n');
  let splitdata = previewText?.split("\n")

  const downloadFile = (file, index,onDownload) => {   
    onDownload(file)
  } 
  const obj = SERVICE_STATUS_OPTIONS.find(({ value }) => value ===data.status);
  return (
    <div className={styles.topSection}>
      <div className={styles.topSectionDiv}>
        {isInternalUser&&(
        <div className={`${styles.requestorDetailsDiv} ${styles.border}`}><span>Organisation Name:</span><span>{data?.organization}</span></div>
        )}
        {/* <div className={styles.requestorDetailsDiv}><span>Requestor Name:</span><span>{isString(data?.createdBy)?data?.createdBy:data.createdBy?.name}</span></div> */}
        {/* <div className={styles.requestorDetailsDiv}><span>Demand ID:</span><span>{data?.requestId}</span></div> */}
        <div className={`${styles.requestorDetailsDiv} ${styles.border} ${isInternalUser?styles.paddingleft:""}`}><span>Service:</span><span>{data?.service}</span></div>
        <div className={`${styles.requestorDetailsDiv} ${styles.border} ${styles.paddingleft}`}><span>Region:</span><span>{data?.locationDetails?.serviceRegion?.label}</span></div>
        <div className={`${styles.requestorDetailsDiv} ${isInternalUser?"":styles.border} ${styles.paddingleft}`}><span>Country:</span><span>{data?.locationDetails?.serviceCountry?.label}</span></div>
        {!isInternalUser&&(
        <div className={`${styles.requestorDetailsDiv} ${styles.paddingleft}`}><span>Requestor:</span>
         <SecondaryTooltip copy={true}text={isString(data?.createdBy)?data?.createdBy:data.createdBy?.email} positions={['bottom', 'right']}>
        <span className={styles.tooltipspan}>{isString(data?.createdBy)?data?.createdBy:data.createdBy?.name}</span>
        </SecondaryTooltip>
        </div>
        )} 
      </div>
      <div className={styles.bottomSectionDiv}>
      {isInternalUser&&(
      <div className={`${styles.serviceDetailDiv} ${styles.border}`}>
        <span>Requestor:</span>
        <SecondaryTooltip copy={true} text={isString(data?.createdBy)?data?.createdBy:data.createdBy?.email} positions={['bottom', 'right']}>
        <span className={styles.tooltipspan}>{isString(data?.createdBy)?data?.createdBy:data.createdBy?.name}</span>
     </SecondaryTooltip>
        </div>
        )}
      {Object.entries(serviceOption).map((option, index) => {
        const borderStyle = isInternalUser ? (index === 2 ? '' : styles.border) : styles.border;
        const paddingLeft = isInternalUser ? styles.paddingleft : (index !== 0 ? styles.paddingleft : '');
       return (<div className={`${styles.serviceDetailDiv} ${paddingLeft} ${borderStyle}`} key={index}>
        <span>{SERVICE_OPTIONS[option[0]]}:</span>
        <span>{(typeof option[1] === 'object' && option[1] !== null) ? option[1].toString() : option[1]}</span>
       </div>);
      })}
        {Object.entries(serviceOption).length>=3&& isInternalUser?<></>:
        <div className={`${styles.serviceDetailDiv} ${styles.paddingleft}`}><span>Submitted On:</span><span>{formatDate(data.createdAt)}</span></div>}
      </div>
      {Object.entries(serviceOption).length>=3&& isInternalUser?
      <div className={styles.bottomSectionDiv}>
        <div className={`${styles.serviceDetailDiv}`}><span>Submitted On:</span><span>{formatDate(data.createdAt)}</span></div> 
      </div>:<></>}
      
    {(data?.comment || data?.files?.length>0) && ( 

      <div className={styles.serviceDescriptionDiv}>
       <div className={styles.infolabel}>Additional Information:</div>
       <div className={styles.infotextflex}>

      <div className={isExpanded ? styles.infotext : styles.infotexts}>
       {isExpanded ? text : (
             newlineIndex === -1 ? (
             splitdata.map((word, index) => (
             index === splitdata.length - 1 ? (
             <span className={styles.info}key={index}>{word}</span>
          )  : (
             <div className={styles.info} key={index}>{word}</div>
          )
          ))
          ) : (
             <span className={styles.info}>{newline}</span>
          )
       )}
        {showViewMore&&(
          <span className={styles.viewMore} onClick={handleToggle}>
           {isExpanded ? " View Less" : " View More"}
          </span>
        )} 
      </div>
       {data?.files?.length > 0 ? 
       <div className={styles.attachmentCont}>
               {/* <span className={styles.atachmentlabel}>Attachments:</span> */}     
              <div className={showViewMore? styles.parentst : styles.parents}>
              {data.files?.map((item,index)=>
              <div className={styles.attachment} key={index} title={item?.originalFileName}>
              <img className={styles.attachlogos} src={getLogo(item?.originalFileName)} />
              <span className={styles.filename}>{item?.originalFileName}</span>
              <img className={styles.attachlog} src={IMAGES.downloadlogo} onClick={() => downloadFile(item, index,onFileDownload)}  />
             </div>)}</div>
      </div>:''} 
      </div>
      </div>
    
          )}

    </div>
  );
}

const CommentBox = (props) => { 
  return (
    // <img className={styles.commentIcon} src={comment} onClick={props.openCommentForm} />
    <button onClick={props.openCommentForm} className={styles.addcomment}>Add Comment</button>
  );
}
const StatusIcon = (props) => { 
  return (
    // <img className={styles.editIcon} src={IMAGES.editIcon} onClick={props.openServiceStatusPopup} />
    <span className={styles.changestatus} onClick={props.openServiceStatusPopup}>Change Status</span>
  );
}
const ServiceDetailHeader = ({data, showCommentPopup,showServiceStatusPopup, ...props}) => {
  const navigate = useNavigate();
  const back = (requestId) => {
    navigate(`/connectivity`);
  };
  return (
    <div className={styles.serviceDetailHeader}>
      {/* <div>Demand Details</div> */}
      <div>
        <img className={styles.back} src={IMAGES.arrows} onClick={() => back()} />
        <span className={styles.demandreqId}>{data?.requestId}</span>
        <StatusContainer statusName={data?.status} />
      </div>
      <div className={styles.iconsContainer}>
      {/* <SectionGuard canAccess={CAN(Action.CREATE_COMMENT, Resource.DEMANDS)}>
        <CommentBox openCommentForm={() => {
          return showCommentPopup(data);
        }} />
      </SectionGuard> */}
        <SectionGuard canAccess={CAN(Action.STATUS_UPDATE, Resource.DEMANDS)}>
            <StatusIcon openServiceStatusPopup={() => {
              return showServiceStatusPopup(data);
            }} />
      </SectionGuard>
      </div>
    </div>
  );

}

// const ServiceCommentsList = ({serviceComments,onFileDownload,fetchData,hasNext,isCommentsLoading,isDownloadStart}) => {
//   return (
//     <div className={styles.commentMainContainer}>
//       { isCommentsLoading && serviceComments.length == 0?
//        <Loader/>:
//       serviceComments.length == 0 ? (
//         <div>No Records Found</div>
//       ) : (
//         <InfiniteScroll
//           dataLength={serviceComments?.length} //This is important field to render the next data
//           next={() => {
//             fetchData();
//           }}
//           hasMore={hasNext}
//           loader={
//               <Loader/>
//           }
//         >
//           {
//            serviceComments.map((comment, index) => {
//             return (
//               <ServiceComment isDownloadStart={isDownloadStart} data={comment} key={index} onFileDownload={onFileDownload}/>
//             );
//           })
//           }
//         </InfiniteScroll>
//       )}
//     </div>
//   )
// }
const Previousconfiguration = ({data}) => { 
  return (
    // <div className={styles.previousMainContainer}>
    //      <div className={styles.previousContainer}>VLAN ID: <span>{data?.vlan}</span></div>
    //       <div className={styles.previousContainer}>Router City: <span>{data?.router_location}</span></div>
    //       <div className={styles.previousContainer}>Router Name:  <span>{data?.pe_ref}</span></div>
    //       <div className={styles.previousContainer}>Allocated Capacity (Mbps): <span>{data?.physical_capacity_gbps}</span></div>
    //       {/*<div className={styles.previousContainer}>Service Id: <span>{data.service_id}</span></div>*/}
    // </div>
    <div className={styles.topSection}>
    <div className={styles.topSectionDiv}>
      <div className={`${styles.requestorDetailsDiv} ${styles.border}`}><span>VLAN ID:</span><span>{data?.vlan}</span></div>
      <div className={`${styles.requestorDetailsDiv} ${styles.border} ${styles.paddingleft}`}><span>Router City:</span><span>{data?.router_location}</span></div>
      <div className={`${styles.requestorDetailsDiv} ${styles.border} ${styles.paddingleft}`}><span>Router Name:</span><span>{data?.pe_ref}</span></div>
      <div className={`${styles.requestorDetailsDiv} ${styles.paddingleft}`}><span>Allocated Capacity (Mbps):</span><span>{data?.physical_capacity_gbps}</span></div>
    </div></div>
  )
}


const ServiceDetailsContainer = ({servicesDetail,isDownloadStart,isCommentsLoading, serviceOption, isInternalUser, serviceComments,onFileDownload,fetchData,hasNext}) => {
  return (
    <div className={styles.serviceDetailContainer}>
        {(servicesDetail && servicesDetail.service_option) ?
          <DetailContainer data={servicesDetail} isDownloadStart={isDownloadStart} serviceOption={serviceOption} isInternalUser={isInternalUser} onFileDownload={onFileDownload} />
          : " No record found"}
        {servicesDetail?.additional_info && Object.keys( servicesDetail.additional_info).length > 0 ? (
          <div className={styles.Previousconfiguration}>
             <div className={styles.PreviousHeader}>Previous Configuration</div>
              <Previousconfiguration data={servicesDetail.additional_info} />
          </div>  
        ) : <></>}
        {/* <div className={styles.commentSection}>
          <div className={styles.commentHeader}>Comments</div>
          <ServiceCommentsList isDownloadStart={isDownloadStart} isCommentsLoading={isCommentsLoading} serviceComments={serviceComments} onFileDownload={onFileDownload} fetchData={fetchData} hasNext={hasNext}/>
        </div> */}
      </div>
    );
}

const Service = (props) => {
  let { requestId } = useParams();
  const serviceDetail = props.servicesDetail; 
  const serviceOption = serviceDetail.service_option;
  const serviceComments = props.comments;
  const [isDownloadStart,setIsDownloadStart]=useState(false);
  let [serviceData, setServiceData] = useState({"id" :requestId}); 
  let _label=props.myRights?.userType==USER_TYPE.INTERNAL?"Connectivity":"My Connectivity";
  const breadcrumbsOption = [
    { label: _label, link: "/connectivity" },
    { label: "Demand Details", link: "" }
  ];
  
  useEffect(() => {
    props.getServiceDetail(requestId);
    let next=null;
    props.getComments(requestId,next);
    window.scrollTo(0,0);
    return () => {
      props.clearDemandComments();
  };
  }, []); 

  const closeCommentsSidebar = () => {
    props.closeCommentForm();
  }
  const showCommentPopup=(data)=>{ 
    const serviceId = {"id" : requestId};    
    data = {
      ...data,
      ...serviceId
  };  
    setServiceData({data});
    props.openCommentForm();
  }
  const showServiceStatusPopup=(data)=>{  
    const serviceId = {"id" : requestId};    
      data = {
        ...data,
        ...serviceId
    };  
    setServiceData({data});
    props.openServiceStatusForm();
  }
  const onFileDownload=(file)=>{   
    setIsDownloadStart(true);
  props.downloadFile(file.originalFileName,file.uniqueFileName).then((result)=>{
      if(result.status){
        /*const fileData=result.file
        if(fileData?.length>0){
              window.open(fileData?.[0]);
        }*/
      }else{
        alert("Error while downloding file!");
      }
      setIsDownloadStart(false);
    }).catch((error)=>{
      alert("Error while downloding file!");
      setIsDownloadStart(false);
    })
  }

  const fetchData=()=>{
      if(props.next){
        props.getComments(requestId,props.next);
      }
  }

  const CommentDetail = ({data, onFileDownload}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };

    // const text = data?.comment;
    // const previewText = text?.slice(0, 100);

    function getInitials(name) {
      const displayName = name?.split(' ')?.filter(word => isNaN(word) && word)?.filter((_, i, arr) => i === 0 || i === arr.length - 1)
          ?.map(word => word[0])?.join('')?.toUpperCase();
      return displayName;
    }
    const downloadFile = (file, index,onDownload) => {   
      onDownload(file)
    }
    // const showViewMore = text?.length>100


    const text = data?.comment ? data.comment.split('\n').map((line, index, arr) => {
      if (index === arr.length - 1) {
        return <span key={index}>{line}</span>;
      } else {
        return <div key={index}>{line}</div>;
      }
    }) : "---";
  
    const previewText = data.comment?.slice(0, 100);
    const newlineIndex = data.comment?.indexOf('\n');
    const newline = newlineIndex !== -1 ? data.comment?.slice(0, newlineIndex) : data.comment;
    const showViewMore = data.comment?.length > 100 ||  data.comment?.includes('\n');
    let splitdata = previewText?.split("\n")
  
    return (
      <div className={styles.commentContainer}>
        <div className={styles.initalshead}>
          <div className={styles.initaltext}>
            <span className={`${styles.inital}`}>{getInitials(data?.createdBy?.name)}</span>
            <span className={styles.initatname}>{data?.createdBy?.name}</span>
            {data?.files?.length>0&&(
               <img className={styles.infoattach} src={IMAGES.attachinfo} />
            )}
          </div>
          <div className={styles.attachments}>
            Posted On:
            <span className={styles.date}>
              {formatDate(data?.createdAt)}
            </span>
          </div>
        </div>
        <div className={styles.comment}>

        {/* {isExpanded ? text : `${previewText}${showViewMore?"...":""}`} */}
        {isExpanded ? text : (
       newlineIndex === -1 ? (
       splitdata.map((word, index) => (
      index === splitdata.length - 1 ? (
        <span className={styles.info}key={index}>{word}</span>
      ) : (
        <div className={styles.info} key={index}>{word}</div>
      )
    ))
  ) : (
    <span className={styles.info}>{newline}</span>
  )
)}
        

        {showViewMore&&(
          <span className={styles.viewMore} onClick={handleToggle}>
           {isExpanded ? " View Less" : " View More"}
          </span>
        )}
        </div>
          <div className={styles.parent}>
          {data?.files?.map((item,index)=>
          <div className={styles.attachment} key={index} title={item?.originalFileName}>
          <img className={styles.attachlogos} src={getLogo(item?.originalFileName)} />
          <span className={styles.filename}>{item?.originalFileName}</span>
          <img className={styles.attachlog} src={IMAGES.downloadlogo} onClick={() => downloadFile(item, index,onFileDownload)}  />
        </div>)}</div>
      </div>
    );
  };

  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Demand Details"}></Banner>
      <ChildContainer>
        {/* <Breadcrumbs options={breadcrumbsOption} /> */}
      </ChildContainer>
      <ChildContainer>
        {!props.isLoading&&(
         <ServiceDetailHeader data={serviceDetail} showCommentPopup={showCommentPopup} showServiceStatusPopup={showServiceStatusPopup} />
        )}
      {/* <Sidebar
        isOpen={props.isCommentFormOpen}
        onClose={closeCommentsSidebar}
        headerTxt={"Add comment"}
      > */}
      <Comment serviceData={serviceData}></Comment>
      <ServiceStatus openFrom={"ServiceDetails"} serviceData={serviceData} status={serviceData.data?.status}></ServiceStatus>

      {/* </Sidebar> */}
      {props.isLoading?<div className={styles.loader}><Loader/></div>:
        <ServiceDetailsContainer isDownloadStart={isDownloadStart} isLoading={props.isLoading} isCommentsLoading={props.isCommentsLoading} fetchData={fetchData} hasNext={props.next} servicesDetail={serviceDetail} serviceOption={serviceOption} isInternalUser={props.myRights?.userType===USER_TYPE.INTERNAL?true:false} serviceComments={serviceComments} onFileDownload={onFileDownload} />
      }
      <div className={styles.listhead}>
          <div className={styles.initaltext}>Comment History</div>
          <div>
            <SectionGuard canAccess={CAN(Action.CREATE_COMMENT, Resource.DEMANDS)}>
              {!props.isCommentsLoading&&(
                <CommentBox openCommentForm={() => {
                  return showCommentPopup(serviceDetail);
                }} />
              )}
            </SectionGuard>
          </div>
      </div>
      {props.isCommentsLoading && props.comments.length == 0? (
          <div className={styles.comentsLoader}>
            <Loader />
          </div>
      ) : props.comments.length == 0 ? (
        <div className={styles.emptydiv}>No Records Found</div>
      ) : (
        <InfiniteScroll
          dataLength={props?.comments?.length} //This is important field to render the next data
          next={() => {
            fetchData();
          }}
          hasMore={props.next}
          loader={<Loader />}
        >
         {props.comments.map((item) => (
            <CommentDetail data={item} isCommentsLoading={props.isCommentsLoading} onFileDownload={onFileDownload}/>
          ))}
        </InfiniteScroll>
      )}
      </ChildContainer>
  
    </Container>
  )
};

export default Service;
