
import {
    BASE_URL, 
    POST,  
    GET,
    PATCH,
    BROADCAST_NOTIFICATION_LIST,
    READ_EVENT_NOTIFICATION
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import { getAccessToken } from "../../authActions/authActions";

export const fetchBroadCastNotificationList = async (requestId) => {

    const token = await getAccessToken();
    const url = `${BASE_URL}${BROADCAST_NOTIFICATION_LIST}`;
    return fetch(url, {
        method: GET,
        headers: {
          "Content-Type": "application/json",
          "userauth" : `bearer ${token}`
        },
      })
      .then(statusParsing)
      .then(jsonParsing);
  };
