const createActionTypes = (prefix) => {
  return ["REQUEST", "SUCCESS", "FAILURE", "CACHED"].reduce((acc, type) => {
    return { ...acc, [type]: `${prefix}_${type}` }
  }, {})
}
/************************* ROLES  *********************/
export const FETCH_MYRIGHTS = createActionTypes("FETCH_MYRIGHTS")
export const UPDATE_MYRIGHTS = "UPDATE_MYRIGHTS"
export const UPDATE_IS_LOGGED_IN = "UPDATE_IS_LOGGED_IN"

/************************* SERVICE  *********************/
export const OPEN_NEWSERVICE_FORM = "OPEN_NEWSERVICE_FORM"
export const CLOSE_NEWSERVICE_FORM = "CLOSE_NEWSERVICE_FORM"
export const SET_ACTIVE_TAB_SERVICES = "SET_ACTIVE_TAB_SERVICES"
export const SET_IS_AMEND_MODE = "SET_IS_AMEND_MODE"
export const SAVE_SERVICE = "SAVE_SERVICE"
export const EDIT_SERVICE = "EDIT_SERVICE"
export const DELETE_SERVICE = "DELETE_SERVICE"
export const CLEAR_SERVICE = "CLEAR_SERVICE"
export const SUBMIT_SERVICE_REQUEST = createActionTypes(
  "SUBMIT_SERVICE_REQUEST"
)
export const FETCH_SERVICES_LIST = createActionTypes("FETCH_SERVICES_LIST")
export const UPDATE_SERVICE_STATUS = createActionTypes("UPDATE_SERVICE_STATUS")
export const DOWNLOAD_SERVICES_LIST = createActionTypes(
  "DOWNLOAD_SERVICES_LIST"
)
/*********************************************************************** */
export const FETCH_LOCATIONS = createActionTypes("FETCH_LOCATIONS")
export const FETCH_CUSTOMERS = createActionTypes("FETCH_CUSTOMERS")
export const FETCH_SERVICE_DETAIL = createActionTypes("FETCH_SERVICE_DETAIL")
export const FETCH_SERVICES = createActionTypes("FETCH_SERVICES")

/************************* COMMENT  *********************/
export const OPEN_COMMENT_FORM = "OPEN_COMMENT_FORM"
export const CLOSE_COMMENT_FORM = "CLOSE_COMMENT_FORM"
export const ADD_COMMENT_FORM = createActionTypes("ADD_COMMENT_FORM")
export const FETCH_COMMENTS = createActionTypes("FETCH_COMMENTS")
export const RESET_DEMAND_COMMENTS = "RESET_DEMAND_COMMENTS"
export const DOWNLOADS = createActionTypes("DOWNLOADS")
/******************IMAGE UPLOADER ******************** */

export const UPLOAD_ATTACHMENT = createActionTypes("UPLOAD_ATTACHMENT")
/************************************DIAMETER*********************************** */
export const FETCH_DIAMETER_INOUT_MESSAGE = createActionTypes(
  "FETCH_DIAMETER_INOUT_MESSAGE"
)
export const FETCH_DIAMETER_INOUT_MESSAGE_BY_Node = createActionTypes(
  "FETCH_DIAMETER_INOUT_MESSAGE_BY_Node"
)
export const UPDATE_DIAMETER_INOUT_MESSAGE_FILTER =
  "UPDATE_DIAMETER_INOUT_MESSAGE_FILTER"
export const UPDATE_DIAMETER_INOUT_MESSAGE_BY_Node_FILTER =
  "UPDATE_DIAMETER_INOUT_MESSAGE_BY_Node_FILTER"
export const FETCH_DIA_FINALTYPE_FINALDIST = createActionTypes(
  "FETCH_DIA_FINALTYPE_FINALDIST"
)
export const FETCH_DIA_SIGNODE_CONNECTIONID = createActionTypes(
  "FETCH_DIA_SIGNODE_CONNECTIONID"
)
export const FETCH_SIG_FINALTYPE_FINALDIST = createActionTypes(
  "FETCH_SIG_FINALTYPE_FINALDIST"
)
export const FETCH_SIG_SIGNODE_CONNECTIONID = createActionTypes(
  "FETCH_SIG_SIGNODE_CONNECTIONID"
)
/*--------Weather MAp----------------------------------------------*/
export const FETCH_WEATHERMAP_PEAK_INOUT_BY_ROUTER = createActionTypes(
  "FETCH_WEATHERMAP_PEAK_INOUT_BY_ROUTER"
)
export const UPDATE_WEATHERMAP_PEAK_INOUT_BY_ROUTER_FILTER =
  "UPDATE_WEATHERMAP_PEAK_INOUT_BY_ROUTER_FILTER"
export const FETCH_WEATHERMAP_FILTER_VALUES = createActionTypes(
  "FETCH_WEATHERMAP_FILTER_VALUES"
)
export const FETCH_WEATHERMAP_VOLUME_INOUT_BY_ROUTER = createActionTypes(
  "FETCH_WEATHERMAP_VOLUME_INOUT_BY_ROUTER"
)
export const UPDATE_WEATHERMAP_VOLUME_INOUT_BY_ROUTER_FILTER =
  "UPDATE_WEATHERMAP_VOLUME_INOUT_BY_ROUTER_FILTER"

/************************************SIGTRAN*********************************** */
export const FETCH_SIGTRAN_INOUT_MESSAGE = createActionTypes(
  "FETCH_SIGTRAN_INOUT_MESSAGE"
)
export const FETCH_SIGTRAN_INOUT_MESSAGE_BY_Node = createActionTypes(
  "FETCH_SIGTRAN_INOUT_MESSAGE_BY_Node"
)
export const UPDATE_SIGTRAN_INOUT_MESSAGE_FILTER =
  "UPDATE_SIGTRAN_INOUT_MESSAGE_FILTER"
export const UPDATE_SIGTRAN_INOUT_MESSAGE_BY_Node_FILTER =
  "UPDATE_SIGTRAN_INOUT_MESSAGE_BY_Node_FILTER"

/***************************SERVICE STATUS******************************************** */

export const OPEN_SERVICE_STATUS_FORM = "OPEN_SERVICE_STATUS_FORM"
export const CLOSE_SERVICE_STATUS_FORM = "CLOSE_SERVICE_STATUS_FORM"
/***************************Generic Information******************************************** */

export const FETCH_IPX_POPS = createActionTypes("FETCH_IPX_POPS")
/************************Live Services  */
export const FETCH_LIVE_SERVICES = createActionTypes("FETCH_LIVE_SERVICES")
export const FETCH_LIVE_SERVICES_FILTER = createActionTypes(
  "FETCH_LIVE_SERVICES_FILTER"
)
export const DOWNLOAD_LIVE_SERVICES_LIST = createActionTypes(
  "DOWNLOAD_Live_SERVICES_LIST"
)

/*********************** UserManagement VF_Users_Group    */
export const FETCH_USERS_LIST = createActionTypes("FETCH_USERS_LIST")
export const CLEAR_USERS_LIST = "CLEAR_USERS_LIST"
export const NEW_USER = createActionTypes("NEW_USER")
export const FETCH_FUNCTIONAL_ROLES = createActionTypes("PRE_DEFINED_ROLES")
export const UPDATE_USER_PERMISSIONS = createActionTypes(
  "UPDATE_USER_PERMISSIONS"
)
export const VERIFY_EMAIL = createActionTypes("VERIFY_EMAIL")
export const FORGOT_PASSWORD = createActionTypes("FORGOT_PASSWORD")
export const SET_ACTIVE_PRIMARY_TAB = "SET_ACTIVE_PRIMARY_TAB"
export const SET_ACTIVE_SECONDARY_TAB = "SET_ACTIVE_SECONDARY_TAB"
export const REDIECT_TAB = "REDIECT_TAB"

export const SUBMIT_ACTIVE_INACTIVE = createActionTypes(
  "SUBMIT_ACTIVE_INACTIVE"
)
export const FETCH_REQUEST_LIST = createActionTypes("FETCH_REQUEST_LIST")
export const FETCH_REQUEST_DETAIL = createActionTypes("FETCH_REQUEST_DETAIL")
export const ONBOARDING_ADD_COMMENT_FORM = createActionTypes(
  "ONBOARDING_ADD_COMMENT_FORM"
)
export const ONBOARDING_APPROVE_REJECT = createActionTypes(
  "ONBOARDING_APPROVE_REJECT"
)
export const FETCH_ONBOARDING_COMMENTS = createActionTypes(
  "FETCH_ONBOARDING_COMMENTS"
)
export const RESET_ONBOARDING_COMMENTS = "RESET_ONBOARDING_COMMENTS"
export const SUBMIT_ONBOARDING_REQUEST = createActionTypes(
  "SUBMIT_ONBOARDING_REQUEST"
)
export const FETCH_ONBOARDING_REQUEST_LIST = createActionTypes(
  "FETCH_ONBOARDING_REQUEST_LIST"
)
export const FETCH_ONBOARDING_REQUEST_DETAIL = createActionTypes(
  "FETCH_ONBOARDING_REQUEST_DETAIL"
)
export const FETCH_ONBOARDING_APPROVAL_LIST = createActionTypes(
  "FETCH_ONBOARDING_APPROVAL_LIST"
)
export const FETCH_ONBOARDING_APPROVAL_DETAIL = createActionTypes(
  "FETCH_ONBOARDING_APPROVAL_DETAIL"
)

export const FETCH_ACCOUNT_ACCESS_REQUEST_LIST = createActionTypes(
  "FETCH_ACCOUNT_ACCESS_REQUEST_LIST"
)
export const FETCH_ACCOUNT_ACCESS_APPROVAL_LIST = createActionTypes(
  "FETCH_ACCOUNT_ACCESS_APPROVAL_LIST"
)
export const FETCH_EXTENSION_REQUEST_DETAIL = createActionTypes(
  "FETCH_EXTENSION_REQUEST_DETAIL"
)
export const FETCH_EXTENSION_APPROVAL_DETAIL = createActionTypes(
  "FETCH_EXTENSION_APPROVAL_DETAIL"
)
export const AM_GRANT_ACCESS = createActionTypes("AM_GRANT_ACCESS")
export const FETCH_ACCOUNT_MANAGER_LIST = createActionTypes(
  "FETCH_ACCOUNT_MANAGER_LIST"
)
export const FETCH_PRIMARY_CONTACT_LIST = createActionTypes(
  "FETCH_PRIMARY_CONTACT_LIST"
)
export const FETCH_ALL_CUSTOMER_LIST = createActionTypes(
  "FETCH_ALL_CUSTOMER_LIST"
)
export const EXTENSION_ACCESS_REQUEST = createActionTypes(
  "EXTENSION_ACCESS_REQUEST"
)
export const ADD_NEW_ACCOUNT_MAMAGER = createActionTypes(
  "ADD_NEW_ACCOUNT_MAMAGER"
)
// /*************************** CUSTOMER ******************************************** */
export const FETCH_CUSTOMER_LIST = createActionTypes("FETCH_CUSTOMER_LIST")
export const SUBMIT_CUSTOMER_DETAILS = createActionTypes(
  "SUBMIT_CUSTOMER_DETAILS"
)
export const FETCH_CUSTOMER_DETAILS = createActionTypes(
  "FETCH_CUSTOMER_DETAILS"
)

// /*************************** Contact Us ******************************************** */
export const SUBMIT_CONTACT_US_DETAILS = createActionTypes(
  "SUBMIT_CONTACT_US_DETAILS"
)

/******************************  Alert Message  *****************************************/
export const OPEN_ALERT_POPUP = "OPEN_ALERT_POPUP"
export const CLOSE_ALERT_POPUP = "CLOSE_ALERT_POPUP"
/*------------Incident Management---------*/
export const SET_INCIDENT_PREVIEW_MODE = "SET_INCIDENT_PREVIEW_MODE"

/******************************  Incident Management  *****************************************/
export const SUBMIT_IVC_SERVICE_REQUEST = "SUBMIT_IVC_SERVICE_REQUEST"
export const SUBMIT_SERVICE_REQUESTS = createActionTypes(
  "SUBMIT_SERVICE_REQUESTS"
)
export const SET_ACTIVE_TAB_INCIDENT = "SET_ACTIVE_TAB_INCIDENT"
export const SUBMIT_INCIDENT_REQUEST = createActionTypes(
  "SUBMIT_INCIDENT_REQUEST"
)
export const FETCH_INCIDENT_LIST = createActionTypes("FETCH_INCIDENT_LIST")
export const FETCH_INCIDENT_DETAIL = createActionTypes("FETCH_INCIDENT_DETAIL")
export const FETCH_SERVICE_LIST = createActionTypes("FETCH_SERVICE_LIST")
export const INCIDENT_ADD_COMMENT_FORM = createActionTypes(
  "INCIDENT_ADD_COMMENT_FORM"
)
export const SERVICE_ADD_COMMENT_FORM = createActionTypes(
  "SERVICE_ADD_COMMENT_FORM"
)
export const FETCH_INCIDENT_COMMENTS = createActionTypes(
  "FETCH_INCIDENT_COMMENTS"
)
export const RESET_INCIDENT_COMMENTS = "RESET_INCIDENT_COMMENTS"
export const FETCH_SERVICE_DETAILS = createActionTypes("FETCH_SERVICE_DETAILS")
export const FETCH_SERVICE_COMMENTS = createActionTypes(
  "FETCH_SERVICE_COMMENTS"
)
export const RESET_SERVICE_COMMENTS = "RESET_SERVICE_COMMENTS"
export const INCIDENT_DOWNLOADS = createActionTypes("INCIDENT_DOWNLOADS")
export const SERVICE_REQUEST_DOWNLOADS = createActionTypes(
  "SERVICE_REQUEST_DOWNLOADS"
)

/******************************  INVOICES  *****************************************/
export const FETCH_INVOICES_LIST = createActionTypes("FETCH_INVOICES_LIST")
export const FETCH_INVOICE_DETAILS = createActionTypes("FETCH_INVOICE_DETAILS")
export const SUBMIT_FINCA_ID = createActionTypes("SUBMIT_FINCA_ID")
export const CLEAR_INVOICE_LIST = "CLEAR_INVOICE_LIST"
export const CLEAR_INVOICE_DETAIL = "CLEAR_INVOICE_DETAIL"
export const FETCH_FINCATADIG = createActionTypes("FETCH_FINCATADIG")

/******************************  Login Alert Pop-up  *****************************************/
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const CLEAR_ALERT_MESSAGE = 'CLEAR_ALERT_MESSAGE';

/****************************** Notification  *****************************************/
export const FETCH_EVENT_NOTIFICATION_LIST = createActionTypes("FETCH_EVENT_NOTIFICATION_LIST")
export const READ_EVENT_NOTIFICATION_LIST = createActionTypes("READ_EVENT_NOTIFICATION_LIST")
export const FETCH_BROADCAST_NOTIFICATION_LIST = createActionTypes("FETCH_BROADCAST_NOTIFICATION_LIST")