import { BASE_URL, POST, GET, GET_INVOICES, FINCA_TADIG } from "../constants"
import { statusParsing, jsonParsing } from "../utils"
import { getAccessToken } from "../../authActions/authActions"

export const fetchAllInvoicesList = async (invoicePeriod) => {

  const token = await getAccessToken()
  const url = `${BASE_URL}${GET_INVOICES}?invoicePeriod=${invoicePeriod}`
  return fetch(url, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`
    }
  })
    .then(statusParsing)
    .then(jsonParsing)
}

export const fetchFincaTADIGList = async () => {

  const token = await getAccessToken()
  const url = `${BASE_URL}${FINCA_TADIG}`
  return fetch(url, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`
    }
  })
    .then(statusParsing)
    .then(jsonParsing)
}
