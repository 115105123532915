import { FETCH_INVOICES_LIST, CLEAR_INVOICE_LIST } from "../actionTypes"
import { fetchAllInvoicesList } from "../../../api/finca/fincaInvoices.service"

const requestListRequest = () => ({ type: FETCH_INVOICES_LIST.REQUEST })
const requestListFailure = (error) => ({type: FETCH_INVOICES_LIST.FAILURE,error})
const requestListSuccess = (obj) => ({ type: FETCH_INVOICES_LIST.SUCCESS, obj })
const clearInvoice = () => ({ type: CLEAR_INVOICE_LIST });

export const getAllInvoicesList = (invoicePeriod) => {
  return (dispatch, getState) => {
    dispatch(requestListRequest())
    return fetchAllInvoicesList(invoicePeriod)
      .then((response) => {
        let obj = {
          data: response.data
        }
        dispatch(requestListSuccess(obj))
        return Promise.resolve(true)
      })
      .catch((error) => {
        dispatch(requestListFailure(error?.message))
        return Promise.resolve(false)
      })
  }
}

export const clearInvoiceList = () => {
  return (dispatch) => {
    dispatch(clearInvoice());
  };
}