import { SUBMIT_ACTIVE_INACTIVE} from "../actionTypes";
import {submitActiveInactive } from "../../../api/userManagement/submitActiveInactive.service";

const request = () => ({ type: SUBMIT_ACTIVE_INACTIVE.REQUEST });
const failure = (error) => ({ type: SUBMIT_ACTIVE_INACTIVE.FAILURE, error });
const success = () => ({ type: SUBMIT_ACTIVE_INACTIVE.SUCCESS });

export const SubmitActiveInactive = (payload,id) => {
  return (dispatch, getState) => {
    dispatch(request());  
    return submitActiveInactive(payload,id)
      .then((res) => { 
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      });
  };
};
