import { FETCH_FINCATADIG } from "../actionTypes"
import { fetchFincaTADIGList } from "../../../api/finca/fincaInvoices.service"

const requestListRequest = () => ({ type: FETCH_FINCATADIG.REQUEST })
const requestListFailure = (error) => ({type: FETCH_FINCATADIG.FAILURE,error})
const requestListSuccess = (obj) => ({ type: FETCH_FINCATADIG.SUCCESS, obj })

export const getFincaTadig = (invoicePeriod) => {
  return (dispatch, getState) => {
    dispatch(requestListRequest())
    return fetchFincaTADIGList()
      .then((response) => {
        let obj={
            'data':response.data,
        }
        dispatch(requestListSuccess(obj))
        return Promise.resolve(true)
      })
      .catch((error) => {
        dispatch(requestListFailure(error?.message))
        return Promise.resolve(false)
      })
  }
}