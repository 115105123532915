import React, { useState } from "react";
import { loginWithEmailIdPassword } from "../../authActions/authActions";
import styles from "./login.module.css";
import { Submit, BackButton, LinkButton } from "../../components/button/buttons";
import { Formik, Form, useField } from 'formik';
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { SIGN_IN_ERROR_MESSAGES, SIGN_IN_STATUS } from "../../authActions/constants";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../components/label/Labels";
import { TextInput } from "../../components/input/inputs";
import Asterisk from "../../components/asterisk/Asterisk";
import eyeIcon from "../../assets/images/eye-icon.png";
import {connect} from "react-redux";
import { forgotPassword } from "../../redux/actions/userManagement/forgotPassword.action";
import IMAGES from "../../assets/images/images";
import { Loader } from "../../components/loader/Loader";
import {setAlertMessage } from '../../redux/actions/AlertErrorMessage/alertErrorMessage.action';
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};

const ForgotPasswordPopup=(props)=>{ 
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });
  const[showMessage, setShowMessage] = useState(false);
  // let [showLoader, setShowLoader]=useState(false);
  let [isFSubmit, setIsFSubmit] = useState(false);
  let [messageColor, setMessageColor] = useState(null);
  const schema = Yup.object().shape({
    emailAddress: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format")   
  });
    return ( 
    <div className={styles.modalContent} >
    <div className={styles.modalHeader}>
      <span className={styles.close} onClick={() => {props.forgotPasswordPopup(); setMessageColor(null)} }>&times;</span>
      <h2>Forgot Password</h2>
      <div className={styles.forgotText}>Please enter your registered Email Address</div>
    </div>
    <div className={styles.modalBody}>
    <Formik
        validationSchema={schema}
        initialValues={{ emailAddress: ""}}
        onSubmit={async (values, { resetForm }) => {
          const maxAttempts = 3;
          const lockoutTime =  30* 60 * 1000; // 30 minutes in milliseconds
          const attempts = JSON.parse(localStorage.getItem('forgotPasswordAttempts')) || { count: 0, firstAttemptTime: null, secondAttemptTime: null, thirdAttemptTime: null };
          const currentTime = new Date().getTime();
          setShowMessage(false);
          //CUP-875
          if (attempts.count >= maxAttempts && currentTime - attempts.thirdAttemptTime < lockoutTime) {
          setMessage({
          message: "You have exceeded the maximum number of attempts. Please try again after some time.",
          image: IMAGES.error,
          });
          setMessageColor(styles.errorcolor);
          setShowMessage(true);
          values.emailAddress = '';
          return;
          }
         // Reset attempts if lockout time has passed
         if (attempts.firstAttemptTime && currentTime - attempts.firstAttemptTime > lockoutTime) {
         attempts.count = 0;
         attempts.firstAttemptTime = null;
         attempts.secondAttemptTime = null;
         attempts.thirdAttemptTime = null;
         }

          setIsFSubmit(true);
          // setShowLoader(true);
          try {
            const res = await props.forgotPassword(values.emailAddress);
            if (res.status) {
              setMessage({
                message: `If your email is in our records, a password reset email has been sent. For further assistance, reach out to your Vodafone point of contact.`,
                image: IMAGES.success,
              });
              // setShowLoader(true);
              setMessageColor(styles.showforgotMessage);
            } else {
              setMessage({
                message: "Something went wrong. Please check your network connection and try again.",
                image: IMAGES.error,
              });
              // setShowLoader(true);
              setMessageColor(styles.errorcolor);
            }
          } catch (error) {
            setMessage({
              message: "Something went wrong. Please check your network connection and try again.",
              image: IMAGES.error,
            });
            // setShowLoader(true);
            setMessageColor(styles.errorcolor);
          } finally {
            setShowMessage(true);
            setIsFSubmit(false);
            resetForm();
            attempts.count += 1;
            if (attempts.count === 1) {
              attempts.firstAttemptTime = currentTime;
            } else if (attempts.count === 2) {
              attempts.secondAttemptTime = currentTime;
            } else if (attempts.count === 3) {
              attempts.thirdAttemptTime = currentTime;
            }
            localStorage.setItem('forgotPasswordAttempts', JSON.stringify(attempts));
            values.emailAddress = '';
          }
        }} 
      >
        {({
          isValid, dirty, values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <TextField              
              type={"email"}
              label={"Email Address"}
              name={"emailAddress"}
              placeholder={"Enter E-mail Address"}
              onChange={(e) => {
                handleChange(e)
                setShowMessage(false)
              }}
              autoComplete="off"
            />            
            <br />
            <ButtonWrapper>
            <BackButton
              style={styles.forgotSubmit}
              title={"Cancel"} 
              closePopup={props.forgotPasswordPopup}
              
            />
            <Submit
              style={styles.forgotSubmit}
              title={"Submit"}
              isSubmitting={isFSubmit}
              disabled={!isValid || !dirty || values.emailAddress === ''}
            />            
            </ButtonWrapper>
            <br />
            {
              //  showLoader?
              //  <div className={styles.loaderContainer}><Loader style={styles.loaderContainer}/></div>:
               showMessage ? <div className={messageColor}>{message.message}</div> : <></>
            }
            <br/><br/>
          </Form>

        )}
      </Formik>
    </div>    
  </div>
  
 
     )
}



const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const TextField = ({ label, disabled, ...props }) => {
  const [field, meta, helpers] = useField(props); 
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError} ${styles.textbox}`
    : `${styles.input} ${styles.textbox}`
  const [showHidePassword, changeShowHidePassword] = useState(false);
  if (props.type == 'password') {  
    props.type = showHidePassword ? "text" : "password";
  }
  return (
    <FieldWrapper>
      {/* <div>
        <Label text={label} /><Asterisk />
      </div> */}
      <TextInput {...field} {...props}  style={inputStyle} disabled={disabled} />
      {props.name == 'password'? (<span onClick={() => changeShowHidePassword(!showHidePassword)} className={styles.passwordToggleIcon}><img src={eyeIcon} /></span>): '' }
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const CustomerLogin = ({forgotPassword,setAlertMessage}) => {
  const navigate = useNavigate();
  let [isSubmit, setIsSubmit] = useState(false);
  let [showPopup, setShowPopup] = useState(false);

  const schema = Yup.object().shape({
    emailId: Yup.string()
      .required("Required"),
      // .email("Invalid email "),
    password: Yup.string()
      .required("Required"),
      // .min(8, "Password must be at least 8 characters"),
  });
  const showForgotPasswordPopup = () => {
    setShowPopup(!showPopup);
  }

  /*const sendForgotPassword = (emailAddress) => {
    forgotPassword(emailAddress);
  }*/

  const login = (emailId, password,resetForm) => {
    loginWithEmailIdPassword(emailId, password)
      .then((res) => {
      })
      .catch((error) => {
        let message = '';
        switch (error) {
          case SIGN_IN_STATUS.INVALID_CREDENTIALS:
            alert(SIGN_IN_ERROR_MESSAGES.INVALID_CREDENTIALS);
            break;
          case SIGN_IN_STATUS.MFA_DISABLED:
            alert(SIGN_IN_ERROR_MESSAGES.MFA_DISABLED);
            break;
          case SIGN_IN_STATUS.UNVERIFIED_EMAIL:
            alert(SIGN_IN_ERROR_MESSAGES.UNVERIFIED_EMAIL);
            break;
          case SIGN_IN_STATUS.TOO_MANY_REQUEST:
            alert(SIGN_IN_ERROR_MESSAGES.TOO_MANY_REQUEST);
            break;
          case SIGN_IN_STATUS.INVALID_VERIFICATION_CODE:
            alert(SIGN_IN_ERROR_MESSAGES.INVALID_VERIFICATION_CODE);
            break;
          case SIGN_IN_STATUS.MISSING_VERIFICATION_CODE:
            alert(SIGN_IN_ERROR_MESSAGES.MISSING_VERIFICATION_CODE);
            break;
          case SIGN_IN_STATUS.RECAPTCHA_ISSUE:
            alert(SIGN_IN_ERROR_MESSAGES.RECAPTCHA_ISSUE);
            break;
          case SIGN_IN_STATUS.USER_DISABLED:
            message = SIGN_IN_ERROR_MESSAGES.USER_DISABLED;
            break;
          default:
            alert(SIGN_IN_ERROR_MESSAGES.RECAPTCHA_ISSUE);
            break;
          }
          setAlertMessage(message);
          setIsSubmit(false);
          resetForm();
        });
    };
  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{ emailId: "", password: "" }}
        onSubmit={(values,{resetForm}) => {
          setIsSubmit(true);
         
          login(values.emailId, values.password,resetForm);
        }}
      >
        {({
          isValid, dirty, values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <TextField              
              type={"email"}
              label={"Email Address"}
              name={"emailId"}
              value={values.emailId}
              placeholder={"Email"}
              onChange={handleChange}
              autoComplete="off"
            />
            <div className={styles.passwordtxt}>
            <TextField              
              type={"password"}
              label={"Password"}
              name={"password"}
              value={values.password}
              placeholder={"Password"}
              onChange={handleChange}
            /></div>
            <div id="captcha-container"></div>   
            <Submit
              style={styles.submit}
              loaderstyle={styles.loader}
              title={"Log In"}
              isSubmitting={isSubmit}
          //    disabled={!isValid || !dirty}
            />
            <div className={styles.ForgotPasswordlink}>
            <LinkButton 
                    style={styles.forgotPasswordlink}
                    title={"Forgot Your Password?"}
                    onClick={() => showForgotPasswordPopup(showPopup)}
                    
                />
            </div>
          </Form>         
        )}
      </Formik>
      {showPopup ? <div id="myModal" className={styles.modal}><ForgotPasswordPopup forgotPassword={forgotPassword}  forgotPasswordPopup={showForgotPasswordPopup} /></div>: <></> }
    </>
  );

};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch)=>{
    return {
        forgotPassword: (emailAddress) => {
            return dispatch(forgotPassword(emailAddress))
        },
        setAlertMessage: (message) => {
          return dispatch(setAlertMessage(message))
        },
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLogin);

