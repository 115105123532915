import React, { useState, useEffect, useRef } from "react";
import styles from "./notification.module.css";
import IMAGES from "../../assets/images/images";
import { formatDate } from "../../utils/arrayUtils";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../components/dropDown/Notificationdropdown";
import clock from "../../assets/images/clock.svg"
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../components/loader/Loader";

const Reminder = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const overlayRef = useRef(null);
  let timer = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timer.current); 
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timer.current = setTimeout(() => {
      setIsOpen(false);
    }, 700);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current); 
    };
  }, []);

  React.useImperativeHandle(ref, () => ({
    handleMouseEnter,
  }));

  const handlereminder = (data) => {
    console.log(data);
  };

  const reminderdata = [
    {
      time: "Remind me Tomorrow",
    },
    {
      time: "Remind me in 3 Hours",
    },
  ];

  return (
    <div
      ref={overlayRef}
      className={styles.mainreminder}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.userIcon}>
        <img className={styles.clockIcon} src={IMAGES.clock} alt="notification" />
      </div>

      {isOpen && (
        <div className={styles.reminderInnerbox}>
          {reminderdata.map((data, index) => (
            <div
              className={styles.reminderoption}
              onClick={() => handlereminder(data.time)}
              key={index}
            >
              {data.time}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});


const Notification = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const overlayRef = useRef(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    props.getEventNotificationList();
  }, [isOpen]);


  // const fetchData = () => {
  //   if () {
  //     props.getEventNotificationList();
  //   }
  // };

  React.useImperativeHandle(ref, () => ({
    handleClick,
  }));

  const count = props.eventnotification.data?.filter(
    (notification) => notification.read === false
  )?.length;



  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
 console.log("selectedOption",selectedOption)
  useEffect(() => {

      const response = [
        { value: 'all', label: 'All', count: 3 },
        { value: 'incident', label: 'Incident Management', count: 4 },
        { value: 'connectivity', label: 'My Connectivity', count: 2 },
      ];
      setOptions(response);
      setSelectedOption(response[0]); // Set default selected option to the first one
    
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);

  };
//   useEffect(() => {
//     props.getspecificmoduledata(selectedOption)
// }, [selectedOption]);

  const navigate = useNavigate();
  const goToServiceDetails = (requestId, detailType) => {
    switch (detailType) {
      case "DEMANDS":
        navigate(`/demands/${requestId}`);
        break;
      case "INCIDENTS":
        navigate(`/incidentDetail/${requestId}`);
        break;
      case "SERVICE_REQUEST":
        navigate(`/ServiceRequestDetail/${requestId}`);
        break;
      case "User_MANAGEMENT":
        navigate(`/user-management?customerId=${encodeURIComponent(requestId)}`);
        break;
      default:
        console.error("Invalid detailType");
    }
  };

  const handleReadNotification = (requestId) => {
    const payload = {
      read: true,
    };
    props.ReadNotification(payload, requestId)
      .then((result) => {
        if (result.status) {
          // Handle success
        } else {
          // Handle failure
        }
      })
      .catch((error) => {
        // Handle error
      });
  };

  const handleClicknotification = (data) => {
    if (data) {
      goToServiceDetails(
        data?.additionalInfo?.detailsId,
        data?.additionalInfo.detailsPageName
      );
      handleReadNotification(data.id);
      props.getEventNotificationList();
       handleClick()
    }
  };

  const sortedNotifications = Array.isArray(props.eventnotification.data)
    ? [...props.eventnotification.data].sort((a, b) => {
      if (a.read === b.read) {
        return b.createdAt - a.createdAt;
      }
      return a.read - b.read;
    })
    : [];

    const fetchData = () => {
      if (props.eventnotification.next) {
        props.getEventNotificationList(props.eventnotification.next);
      }
    };

  return (
    <div ref={overlayRef}>
      <button className={styles.applybtn} onClick={handleClick}>
        <div className={styles.icon}>
          <div><img src={IMAGES.notification} className={styles.notificationicon} alt="notification" /></div>
          {count !== 0 && <div className={styles.count}>{count}</div>}
        </div>
      </button>

      {isOpen && (
        <div className={styles.overlayInnerbox}>
          <div className={styles.headerContainer}>
            <div className={styles.dropdown}>
              <Dropdown
                options={options}
                value={selectedOption}
                onChange={handleChange}
              />
            </div>
          </div>
          {props.eventnotification.isLoading && props.eventnotification.data.length == 0 ? (
                  <Loader />
                ) : props.eventnotification.data.length == 0 ? (
                  <div className={styles.Emptydiv}>No New Notifications Found</div>
                ) : (
                  <InfiniteScroll
                    dataLength={props.eventnotification.data?.length}
                    next={() => {
                      fetchData();
                    }}
                    hasMore={props.eventnotification.next}
                    loader={<Loader />}
                  >
                  
                      <div className={styles.notificationContainer}>
                      {sortedNotifications?.map((data) => (
          
                        <div
                          key={data?.notificationEventId}
                          className={styles.block}
                          style={{ backgroundColor: data?.read ? "#FFFFFF" : "#F9F9F9" }}
                         
                        >
                          <div className={styles.mainblock}>
                            <div className={styles.newmessage}>
                            {!data?.read &&  <div className={styles.new}></div>}
                            </div>
                            <div className={styles.blockinner}>
                              <div className={styles.blocktop}>
                                <div>
                                  <span className={data.read ?styles.id :styles.idread}   onClick={() => handleClicknotification(data)}>INC-2112211-1 </span>
                                  <span className={data.read ?styles.status:styles.statusread}>- Approved</span>
                                </div>
                                <div>
                               <Reminder/>
                                </div>
                              </div>
                              <div className={data.read ? styles.notificationmessage : styles.notificationread }>Onboarding request for Telefonica Global Solutions has been approved by Commercial Manager</div>
                              <div className={styles.bottomdiv}>
                                <div className={styles.module}>My Tickets</div>
                                <div className={styles.module}>4 minutes ago</div>
                              </div>
          
                            </div>
                          </div>
                        </div>
          
                      ))}
                    </div>
                   
                  </InfiniteScroll>
                )}

         
        </div>
      )}
    </div>
  );
});

export default Notification;



