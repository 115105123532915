import { setAlertMessage } from "../../redux/actions/AlertErrorMessage/alertErrorMessage.action";
import Login from "./Login";
import {connect} from "react-redux";

const mapStateToProps = null;

const mapDispatchToProps = (dispatch)=>{
    return {
        setAlertMessage: (message) => {
            return dispatch(setAlertMessage(message))
          },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);


