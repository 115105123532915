import InvoiceDetails from "./InvoiceDetails"
import { connect } from "react-redux"

import { clearInvoiceDetail, getInvoiceDetails } from "../../../redux/actions/finca/invoiceDetails.action"

const mapStateToProps = (state) => {
  // console.log("state", state.invoiceDetailsReducer)
  return {
    invoiceData: state.invoiceDetailsReducer.data,
    myRights: state.myRights.myRights,
    isLoading: state.invoiceDetailsReducer.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInvoiceDetails: (invoicePeriod, fincaId) => {
      // console.log(" in dispatch invoicePeriod=>", invoicePeriod, fincaId)
      return dispatch(getInvoiceDetails(invoicePeriod, fincaId))
    },
    clearInvoiceDetail:()=>{
      return dispatch(clearInvoiceDetail())
  },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails)
