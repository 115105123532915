import { SET_ALERT_MESSAGE, CLEAR_ALERT_MESSAGE } from './../../actions/actionTypes';

const initialState = {
    alertMessage: '',
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT_MESSAGE:
      return { ...state, alertMessage: action.payload };
    case CLEAR_ALERT_MESSAGE:
      return { ...state, alertMessage: '' };
    default:
      return state;
  }
};

export default alertReducer;