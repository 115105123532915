import { FETCH_INVOICES_LIST, CLEAR_INVOICE_LIST } from "../../actions/actionTypes";

const initialState = {
    data: [],
    isLoading: false,
    next: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVOICES_LIST.REQUEST:
            return { ...state, isLoading: true };
        case FETCH_INVOICES_LIST.FAILURE:
            return { ...state, isLoading: false };
        case FETCH_INVOICES_LIST.SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.obj.data], // Append new data to existing state
                isLoading: false
            };
        case CLEAR_INVOICE_LIST:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
