import { FETCH_ONBOARDING_REQUEST_DETAIL } from "../../actions/actionTypes";

const initialState = {  
    onBoardingRequestDetail: {},
    loading: false,
};

const reducer = (state = initialState, action) => {
    
    switch (action.type) {    
        case FETCH_ONBOARDING_REQUEST_DETAIL.REQUEST:
            return {...state,onBoardingRequestDetail: {},loading: true}
        case FETCH_ONBOARDING_REQUEST_DETAIL.FAILURE:
            return {...state,onBoardingRequestDetail: {}, loading: false}
        case FETCH_ONBOARDING_REQUEST_DETAIL.SUCCESS:             
            return {...state, onBoardingRequestDetail: action.obj.data, loading: false}    
        default:
            return state
    }
}

export default reducer;